import React from "react";
import { Box, Menu, MenuItem, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

function MenuButtonList() {
  // form
  const [form, setForm] = React.useState(null);
  function formClick(event) {
    if (form !== event.currentTarget) {
      setForm(event.currentTarget);
    }
  }
  function formClosed() {
    setForm(null);
  }

  // battery lab
  const [lab, setLab] = React.useState(null);
  function labClick(event) {
    if (lab !== event.currentTarget) {
      setLab(event.currentTarget);
    }
  }
  function labClosed() {
    setLab(null);
  }

  // Van Reservation
  const [van, setVan] = React.useState(null);
  function vanClick(event) {
    if (van !== event.currentTarget) {
      setVan(event.currentTarget);
    }
  }
  function vanClosed() {
    setVan(null);
  }

  // Attendance
  const [atd, setAtd] = React.useState(null);
  function atdClick(event) {
    if (atd !== event.currentTarget) {
      setAtd(event.currentTarget);
    }
  }
  function atdClosed() {
    setAtd(null);
  }

  const MenuButtonListItems = [
    {
      menuId: 0,
      menuTitle: "Form",
      menuPath: "form",
      menuFunction: form,
      menuClick: formClick,
      menuClose: formClosed,
      submenu: [
        {
          id: 0,
          submenuTitle: "Purchase Requisition",
          submenuPath: `form/procurement`,
        },
      ],
    },

  ];

  return (
    <>
      {MenuButtonListItems.map((menuBtnItem) => {
        return (
          <Box
            key={menuBtnItem.menuId}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <MenuItem
              component={Link}
              to={`/${menuBtnItem.menuPath}`}
              display="flex"
              sx={{ width: 190, pr: 1 }}
              open={Boolean(menuBtnItem.menuFunction)}
              onMouseOver={menuBtnItem.menuClick}
              // onMouseLeave={menuBtnItem.menuClose}
              onClose={menuBtnItem.menuClose}
            >
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontWeight: "bold",
                  width: 180,
                  color: 'black'
                }}
              >
                {menuBtnItem.menuTitle}
              </Typography>
              {menuBtnItem.menuFunction ? (
                <ArrowBackIos justify="flex-end" sx={{ fontSize: 14 }} />
              ) : (
                <ArrowForwardIos justify="flex-end" sx={{ fontSize: 14 }} />
              )}
            </MenuItem>
            <Menu
              anchorEl={menuBtnItem.menuFunction}
              open={Boolean(menuBtnItem.menuFunction)}
              onClose={menuBtnItem.menuClose}
              onMouseLeave={menuBtnItem.menuClose}
              MenuListProps={{ onMouseLeave: menuBtnItem.menuClose }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {menuBtnItem.submenu.map((submenu, index) => {
                return (
                  <MenuItem
                    key={index}
                    component={Link}
                    to={`/${submenu.submenuPath}`}
                  >
                    <Typography
                      sx={{ fontFamily: "Montserrat", fontWeight: "bold", color: "black" }}
                    >
                      {submenu.submenuTitle}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Menu>
          </Box>
        );
      })}
    </>
  );
}

export default MenuButtonList;
