import { Box, Grid, Typography } from "@mui/material";

import React from "react";

function ShippingCondition(props) {
  let { shippingObject, exchangeRate } = props;
  let currdict = { 
            "THB":1,
            "USD":38,
            "GBP":43,
            "SGD":27,
            "HKD":5,
            "EUR":38,
            "CNY":5,
            "AUD":24, 
            }
  if ((shippingObject.currency in currdict) && (exchangeRate === undefined))  {
    exchangeRate = currdict[shippingObject.currency]
  }

  return (
    <Box sx={{ width: "1200px" }}>
      {/* -------------------------------- project -------------------------------- */}
      <Grid container spacing={1} sx={{ paddingBottom: 3 }}>
        {/* -------------------------------- project -------------------------------- */}
        <Grid container>
          <Grid width="130px">
            <Typography sx={{ fontSize: 14 }}>1. Project Name:</Typography>
          </Grid>
          <Grid width="1070px">
            <Typography sx={{ fontSize: 14 }}>
              {shippingObject.projectName}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid width="130px">
            <Typography sx={{ fontSize: 14 }}>2. Purpose:</Typography>
          </Grid>
          <Grid width="1070px">
            <Typography sx={{ fontSize: 14 }}>
              {shippingObject.projectPurpose}
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{ paddingBottom: 5 }} />
        {/* -------------------------------- condition -------------------------------- */}
        <Typography
          sx={{ fontWeight: "bold", fontSize: 16, textDecoration: "underline" }}
        >
          Conditions:
        </Typography>
        {/* ----- term of payment ----- */}
        <Grid container>
          <Grid width="280px">
            <Typography sx={{ fontSize: 14 }}>1. Term of Payment:</Typography>
          </Grid>
          <Grid width="920px">
            <Typography sx={{ fontSize: 14 }}>
              {shippingObject.termOfPayment}
            </Typography>
          </Grid>
        </Grid>
        {/* ----- payment method ----- */}
        <Grid container>
          <Grid width="280px">
            <Typography sx={{ fontSize: 14 }}>2. Payment method:</Typography>
          </Grid>
          <Grid width="920px">
            <Typography sx={{ fontSize: 14 }}>
              {shippingObject.paymentMethod}
            </Typography>
          </Grid>
        </Grid>
        {/* ----- delay penalty ----- */}
        <Grid container>
          <Grid width="280px">
            <Typography sx={{ fontSize: 14 }}>3. Delay Penalty:</Typography>
          </Grid>
          <Grid width="920px">
            <Typography sx={{ fontSize: 14 }}>
              {shippingObject.delayPenalty}
            </Typography>
          </Grid>
        </Grid>
        {/* ----- defect and warranty ----- */}
        <Grid container>
          <Grid width="280px">
            <Typography sx={{ fontSize: 14 }}>
              4. Defect and Warranty:
            </Typography>
          </Grid>
          <Grid width="920px">
            <Typography sx={{ fontSize: 14 }}>
              {shippingObject.defectWarning}
            </Typography>
          </Grid>
        </Grid>
        {/* ----- currency and exchangerate detail ----- */}
        <Grid container>
          <Grid width="280px">
            <Typography sx={{ fontSize: 14 }}>
              5. Currency and Exchange Rate:
            </Typography>
          </Grid>
          <Grid width="920px">
            <Typography sx={{ fontSize: 14 }}>
              {` 1 ${shippingObject.currency} ~ ${exchangeRate} THB`}
            </Typography>
          </Grid>
        </Grid>
        {/* ----- contact no ----- */}
        <Grid container>
          <Grid width="280px">
            <Typography sx={{ fontSize: 14 }}>6. Contract No.:</Typography>
          </Grid>
          <Grid width="920px">
            <Typography sx={{ fontSize: 14 }}>
              {shippingObject.contractNo}
            </Typography>
          </Grid>
        </Grid>
        {/* ----- require purchase order ----- */}
        <Grid container>
          <Grid width="280px">
            <Typography sx={{ fontSize: 14 }}>
              7. Payment Due:
            </Typography>
          </Grid>
          <Grid width="920px">
            <Typography sx={{ fontSize: 14 }}>
              {new Date(shippingObject.paymentDue).toLocaleDateString()}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ShippingCondition;
