import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";

import MuLogoBlackText from "../../../assets/logo/mu_logo_black.png";

function ProcurementHeader(props) {
  const { optionalInfo, prNumber, prForm, setPrForm } = props;

  var dateVariable = new Date();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var month = months[dateVariable.getMonth()];
  // year
  var shortYear = dateVariable.getFullYear();
  var twoDigitYear = shortYear.toString().substr(-2);

  // month num with 0
  const monthNumList = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  var monthPR = monthNumList[dateVariable.getMonth()];

  useEffect(() => {
    const setPRnumber = () => {
      const PR = `MUS${optionalInfo.location[0]}${twoDigitYear}${monthPR}-${prNumber}`;
      setPrForm(PR);
    };
    setPRnumber();
  }, [monthPR, optionalInfo.location, prNumber, setPrForm, twoDigitYear]);

  return (
    <Box sx={{ width: "1200px" }}>
      <Grid container spacing={1} sx={{ paddingBottom: 3 }}>
        {/* -------------------------------- logo -------------------------------- */}
        <Grid width="180px">
          <Box
            component="img"
            alt="mu space logo with text black"
            src={MuLogoBlackText}
            sx={{ height: "100px" }}
          />
        </Grid>
        {/* -------------------------------- address -------------------------------- */}
        <Grid width="720px">
          <Box sx={{ paddingBottom: 1 }}>
            <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
              mu Space and Advanced Technology Co., Ltd. (Head office)
            </Typography>
            <Typography sx={{ fontSize: 14 }}>
              No. 1177 Pearl Bangkok Building, 12A Floor, Room number 12A04-05,
              {/* Phahonyothin Road, */}
            </Typography>
            <Typography sx={{ fontSize: 14 }}>
              Phahonyothin Road, Phayathai, Phayathai, Bangkok, 10400 Thailand
            </Typography>
            <Typography sx={{ fontSize: 14 }}>Tax ID: 0105560101507</Typography>
            <Typography sx={{ fontSize: 14 }}>
              Tel. +662 028 9425-8 Email: billing@muspacecorp.com
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontFamily: "sarabun",
                fontSize: 16,
                fontWeight: "bold",
              }}
            >
              บริษัท มิว สเปซ แอนด์ แอดวานซ์ เทคโนโลยี จำกัด (สำนักงานใหญ่)
            </Typography>
            <Typography sx={{ fontFamily: "sarabun", fontSize: 14 }}>
              เลขที่ 1177 อาคารเพิร์ล แบงก์ค๊อก ชั้น 12A ห้องเลขที่ 12A04-05
              ถนนพหลโยธิน
            </Typography>
            <Typography sx={{ fontFamily: "sarabun", fontSize: 14 }}>
              แขวงพญาไท เขตพญาไท กรุงเทพมหานคร 10400
            </Typography>
            <Typography sx={{ fontFamily: "sarabun", fontSize: 14 }}>
              เลขประจำตัวผู้เสียภาษี 0105560101507
            </Typography>
            <Typography sx={{ fontFamily: "sarabun", fontSize: 14 }}>
             โทร. +662 028 9425-8 E-mail: billing@muspacecorp.com
            </Typography>
          </Box>
        </Grid>
        {/* -------------------------------- date/pr no. -------------------------------- */}
        <Grid align="right" width="300px">
          <Box
            sx={{
              width: "250px",
              backgroundColor: "#097969",
              textAlign: "center",
              color: "white",
              padding: 1.5,
            }}
          >
            <Typography sx={{ fontWeight: "bold", fontSize: 20 }}>
              Purchase
            </Typography>
            <Typography sx={{ fontWeight: "bold", fontSize: 20 }}>
              Requisition
            </Typography>
          </Box>
          {/* ----- date ----- */}
          <Grid align="right" container sx={{ paddingTop: 2 }}>
            <Grid width="40px" />
            <Grid width="60px">
              <Typography sx={{ fontSize: 14 }}>Date</Typography>
            </Grid>
            <Grid width="200px" sx={{ textAlign: "left", paddingLeft: 1 }}>
              <Box sx={{ border: 1, paddingLeft: 1 }}>
                <Typography sx={{ fontSize: 14 }}>
                  {`${month} ${dateVariable.getDate()}, ${dateVariable.getFullYear()}`}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          {/* ----- pr no ----- */}
          <Grid align="right" container sx={{ paddingTop: 0.5 }}>
            <Grid width="40px" />
            <Grid width="60px">
              <Typography sx={{ fontSize: 14 }}>PR No.</Typography>
            </Grid>
            <Grid width="200px" sx={{ textAlign: "left", paddingLeft: 1 }}>
              <Box sx={{ border: 1, paddingLeft: 1 }}>
                <Typography sx={{ fontSize: 14 }}>{prForm}</Typography>
              </Box>
            </Grid>
          </Grid>
          {/* ----- rev ----- */}
          {/* <Grid align="right" container sx={{ paddingTop: 0.5 }}>
            <Grid width="40px" />
            <Grid width="60px">
              <Typography sx={{ fontSize: 14 }}>rev</Typography>
            </Grid>
            <Grid width="200px" sx={{ textAlign: "left", paddingLeft: 1 }}>
              <Box sx={{ border: 1, paddingLeft: 1 }}>
                <Typography sx={{ fontSize: 14 }}>1</Typography>
              </Box>
            </Grid>
          </Grid> */}
        </Grid>
      </Grid>
    </Box>
  );
}

export default ProcurementHeader;
