import React from "react";
import { Box, Grid, Typography } from "@mui/material";

function VendorReqTable(props) {
  const { vendorObject } = props;
  return (
    <Box sx={{ width: "1200px" }}>
      <Grid container spacing={1} sx={{ paddingBottom: 3 }}>
        {/* -------------------------------- vendor/supplier -------------------------------- */}
        <Grid width="599px">
          <Box
            sx={{ backgroundColor: "#097969", color: "white", paddingLeft: 1 }}
          >
            <Typography sx={{ fontWeight: "bold" }}>Vendor/Supplier</Typography>
          </Box>
          {/* ----- code ----- */}
          <Grid container>
            <Grid width="130px">
              <Typography sx={{ paddingLeft: 1, fontSize: 14 }}>
                Code:
              </Typography>
            </Grid>
            <Grid width="469px">
              <Typography sx={{ fontSize: 14 }}>
                {vendorObject.vendorCode}
              </Typography>
            </Grid>
          </Grid>
          {/* ----- name ----- */}
          <Grid container>
            <Grid width="130px">
              <Typography sx={{ paddingLeft: 1, fontSize: 14 }}>
                Name:
              </Typography>
            </Grid>
            <Grid width="469px">
              <Typography sx={{ fontSize: 14 }}>
                {vendorObject.vendorName}
              </Typography>
            </Grid>
          </Grid>
          {/* ----- address ----- */}
          <Grid container>
            <Grid width="130px">
              <Typography sx={{ paddingLeft: 1, fontSize: 14 }}>
                Address:
              </Typography>
            </Grid>
            <Grid width="469px">
              <Typography sx={{ fontSize: 14 }}>
                {vendorObject.vendorAddress}
              </Typography>
            </Grid>
          </Grid>
          {/* ----- contact name ----- */}
          <Grid container>
            <Grid width="130px">
              <Typography sx={{ paddingLeft: 1, fontSize: 14 }}>
                Contact Name:
              </Typography>
            </Grid>
            <Grid width="469px">
              <Typography sx={{ fontSize: 14 }}>
                {vendorObject.vendorContact}
              </Typography>
            </Grid>
          </Grid>
          {/* ----- email ----- */}
          <Grid container>
            <Grid width="130px">
              <Typography sx={{ paddingLeft: 1, fontSize: 14 }}>
                E-mail:
              </Typography>
            </Grid>
            <Grid width="469px">
              <Typography sx={{ fontSize: 14 }}>
                {vendorObject.vendorEmail}
              </Typography>
            </Grid>
          </Grid>
          {/* ----- phone ----- */}
          <Grid container>
            <Grid width="130px">
              <Typography sx={{ paddingLeft: 1, fontSize: 14 }}>
                Phone:
              </Typography>
            </Grid>
            <Grid width="469px">
              <Typography sx={{ fontSize: 14 }}>
                {vendorObject.vendorPhone}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container width="2px"></Grid>
        {/* -------------------------------- requestor -------------------------------- */}
        <Grid width="599px">
          <Box
            sx={{ backgroundColor: "#097969", color: "white", paddingLeft: 1 }}
          >
            <Typography sx={{ fontWeight: "bold" }}>Requestor</Typography>
          </Box>
          {/* ----- location ----- */}
          <Grid container>
            <Grid width="130px">
              <Typography sx={{ paddingLeft: 1, fontSize: 14 }}>
                Location:
              </Typography>
            </Grid>
            <Grid width="469px">
              <Typography sx={{ fontSize: 14 }}>
                {vendorObject.requestorLocation}
              </Typography>
            </Grid>
          </Grid>
          {/* ----- request by ----- */}
          <Grid container>
            <Grid width="130px">
              <Typography sx={{ paddingLeft: 1, fontSize: 14 }}>
                Requested by:
              </Typography>
            </Grid>
            <Grid width="469px">
              <Typography sx={{ fontSize: 14 }}>
                {vendorObject.requestorName}
              </Typography>
            </Grid>
          </Grid>
          {/* ----- email ----- */}
          <Grid container>
            <Grid width="130px">
              <Typography sx={{ paddingLeft: 1, fontSize: 14 }}>
                E-mail:
              </Typography>
            </Grid>
            <Grid width="469px">
              <Typography sx={{ fontSize: 14 }}>
                {vendorObject.requestorEmail}
              </Typography>
            </Grid>
          </Grid>
          {/* ----- phone ----- */}
          <Grid container>
            <Grid width="130px">
              <Typography sx={{ paddingLeft: 1, fontSize: 14 }}>
                Phone:
              </Typography>
            </Grid>
            <Grid width="469px">
              <Typography sx={{ fontSize: 14 }}>
                {vendorObject.requestorPhone}
              </Typography>
            </Grid>
          </Grid>
          {/* ----- Team ----- */}
          <Grid container>
            <Grid width="130px">
              <Typography sx={{ paddingLeft: 1, fontSize: 14 }}>
                Team:
              </Typography>
            </Grid>
            <Grid width="469px">
              <Typography sx={{ fontSize: 14 }}>
                {vendorObject.requestorTeam}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default VendorReqTable;
