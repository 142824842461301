import { Box, useTheme } from "@mui/material";
import React from "react";
import Appbar from "../../components/Appbar/Appbar";
import FormContent from "./FormContent";
import { Outlet } from "react-router-dom";

const Form = ({ user }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        minHeight: "100vh",
        background: "black",
        // background: theme.palette.background.dashboardGradient,
      }}
    >
      <Appbar user={user} />

      <FormContent />
    </Box>
  );
};

export default Form;
