import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyD6Lw2TT0UdSIy_5cFFbV-IeeKej0hwoiU",
  authDomain: "portal-web-authenticator.firebaseapp.com",
  projectId: "portal-web-authenticator",
  storageBucket: "portal-web-authenticator.appspot.com",
  messagingSenderId: "683825406191",
  appId: "1:683825406191:web:758d5042de0a3d631d3b1a",
  measurementId: "G-8NZK5H432S",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export const provider = new GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });
