import React from "react";
import { ColorModeContext } from "../context/ThemeContext";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { colors, useMediaQuery } from "@mui/material";

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === "light"
      ? {
        // * Light Mode Settings.
        primary: colors.grey,
        secondary: colors.blueGrey,
        background: {
          appbar: "#E2E2E2",
          dashboard: "#e9e9e9",
          dashboardGradient: "linear-gradient(to right bottom,#F1F2F6,#C9C6C6)",
          appbarGradient: 'linear-gradient(to right bottom, #DAE2F8,#D4D3DD)',
          form: "#d2d2d2",
          servicebutton: "#cfcfcf",
        },
        text: {
          primary: "#000",
          secondary: "#000",
          appbar: "#000",
          black: "#000",
          white: "#fff",
          disabled: "#737373",
        },
      }
      : {
        // * Dark Mode Settings.
        primary: colors.grey,
        secondary: colors.blueGrey,
        background: {
          appbar: "#131313",
          dashboard: "#656565",
          form: "#737373",
          servicebutton: "#c4c4c4",
        },
        text: {
          primary: "#fff",
          secondary: "#fff",
          appbar: "#fff",
          black: "#000",
          disabled: "#737373",
        },
      }),
  },
  typography: {
    fontFamily: 'montserrat'
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        sx: {
          "& .MuiInputBase-root": {
            backgroundColor: "#f1f1f1",
            borderRadius: '10px',
            "&.Mui-disabled fieldset": {
              color: "#000000",
            },
          },
          "& svg.MuiSvgIcon-root": {
            color: "black",
          },
          "& .MuiOutlinedInput-root": {
            color: "black",
            "&:hover fieldset": {
              borderColor: "black",
              borderWidth: '2px'
            },
            "&.Mui-focused fieldset": {
              borderColor: "black",
            },
          },
        }
      },
    },
    MuiInputLabel: {
      defaultProps: {
        sx: {
          color: 'black',
          backgroundColor: "#f1f1f1",

        }
      }
    },
    MuiOutlinedInput: {
      defaultProps: {
        sx: {
          backgroundColor: "#f1f1f1",
          borderRadius: '10px',
          color: 'black',
          "&:hover fieldset": {
            borderRadius: '10px',
            borderColor: 'black',
            borderWidth: '2px',
          },
        }
      }
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'outlined',
        sx: {

          "& svg.MuiSvgIcon-root": {
            color: "black",
          },
          "& .MuiTextField-root": {
            color: "black",
          },
          "&:hover": {
            borderColor: "black",
            borderWidth: '2px',
            borderRadius: '10px',
          },
        },
      }
    },

  },
});

const AppThemeProvider = (props) => {

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const [mode, setMode] = React.useState("light");

  const toggleColorMode = () => {
    // setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
    setMode(prefersDarkMode ? 'dark' : 'light',);
  };
  const setDarkMode = () => {
    setMode("dark");
  };
  const setLightMode = () => {
    setMode("light");
  };


  const theme = createTheme(getDesignTokens(mode));

  return (
    <ColorModeContext.Provider
      value={{ mode, toggleColorMode, setDarkMode, setLightMode }}
    >
      <ThemeProvider
        theme={theme}>
        {props.children}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default AppThemeProvider;
