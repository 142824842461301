import * as React from "react";
import { Box, Menu, Button, MenuItem, Typography } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Link } from "react-router-dom";
import MenuButtonList from "./MenuButtonList";

function MenuButton() {
  const [menu, setMenu] = React.useState(null);

  function menuClick(event) {
    if (menu !== event.currentTarget) {
      setMenu(event.currentTarget);
    }
  }

  function menuClosed() {
    setMenu(null);
  }

  return (
    <Box>
      <Button
        sx={{ mx: 2, color: "white" }}
        endIcon={menu ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        aria-haspopup="true"
        // onMouseOver={menuClick}
        onClick={menuClick}
      >
        <Typography sx={{ fontFamily: "Montserrat", fontWeight: "bold" }}>
          Menu
        </Typography>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={menu}
        open={Boolean(menu)}
        onClose={menuClosed}
        // onMouseLeave={menuClosed}
        MenuListProps={{ onMouseLeave: menuClosed }}
        PaperProps={{ sx: { mt: 1.5 } }}
      >
        <MenuItem component={Link} to={`home`}>
          <Typography sx={{ fontFamily: "Montserrat", fontWeight: "bold", color: 'black' }}>
            Home
          </Typography>
        </MenuItem>
        <MenuButtonList />
      </Menu>
    </Box>
  );
}

export default MenuButton;
