const requestorLocation = [
  {
    fullLocation:
      "Head Office: No. 1177 Pearl Bangkok Building 12A floor Room number 12A04-05 Phahonyothin Road Phayathai Phayathai Bangkok 10400 Thailand",
    shortLocation: "Head Office: Pearl Bangkok Building",
  },
  {
    fullLocation:
      "Factory 0: 71, 31 Vibhavadi Rangsit 64 Alley, Talat Bang Khen, Lak Si, Bangkok 10210 Thailand",
    shortLocation: "Factory 0",
  },
  {
    fullLocation:
      "Factory 1: 160, 2 Vibhavadi Rangsit Rd, Talat Bang Khen, Lak Si, Bangkok 10210 Thailand",
    shortLocation: "Factory 1",
  },
  {
    fullLocation:
      "Factory 2:  158 Soi Vibhavadi Rangsit 62 Yaek 1, Talat Bang Khen, Lak Si, Bangkok 10210 Thailand",
    shortLocation: "Factory 2",
  },
];

const requestorTeam = [
  "Accounting",
  "Business Development",
  "Engineering",
  "Finance",
  "HR & Admin",
  "IT",
  "Legal",
  "Marketing",
  "Media",
  "Operations",
  "Procurement",
];

const shippingMethod = [
  "Air Freight",
  "Sea Freight",
  "Truck",
  "Other",
];

const deliveryTerm = [
  "Door-to-Door",
  "EXW",
  "FOB",
  "FCA",
  "FAS",
  "C&R",
  "CIF",
  "CPT",
  "CIP",
  "DDU",
  "DDP",
  "DAF",
  "DES",
  "DEQ",
  "DAP",
];

const projectName = [
  "Factory 00 - Renovation",
  "Factory 01 - Renovation",
  "Factory 02 - Renovation",
  "Pearl - Renovation only",
  "5G Macro (BB Tech - DTAC)",
  "Nemo 5G Test",
  "OSP Fiber",
  "Optical Fiber MA",
  "Solar Project",
  "Satellite Monitoring-RBC",
  "GEO Satellite",
  "Satellite Monitoring-USSI",
  "Satellite BW",
  "Satellite Monitoring Facility Service",
  "Back office - Admin",
  "BD and Commercial",
  "Laboratory",
  "Media",
  "Marketing",
  "Accounting",
  "HR",
  "Australia (Operating only)",
  "(R&D) 3D Printing",
  "(R&D) Advanced Materials",
  "(R&D) AI",
  "(R&D) Battery Development",
  "(R&D) Chip-scale Atomic Clock",
  "(R&D) Laser Satcom",
  "(R&D) Lithography",
  "(R&D) Robot",
  "(R&D) Optic",
  "(R&D) Power Electronics Development",
  "(R&D) Satellite",
  "(R&D) Thermal Vacuum Chamber",
  "C-suite travel expense",

];

const shippingVia = [{
  brand: "Self",
}, {
  brand: "DHL",
}, {
  brand: "FedEx",
}, {
  brand: "Thailand Post"
}, {
  brand: "Kerry",
}, {
  brand: "J&T",
}, {
  brand: "Flash Express",
}, {
  brand: "Ninjavan",
}, {
  brand: "Shopee Express",
}, {
  brand: "UPS",
}];

const paymentMethod = [
  "Transfer",
  "Cash",
  "Cheque",
  "Corporate Card",
  "Employee's account"
];

const itemCategory = [
  "IT Equipment and Machine",
  "Engineer Equipment and Machine",
  "Office Equipment and Furniture",
  "Vehicle",
  "Software",
  "Material",
  "Expense",
];

const itemCurrency = [
  "AUD",
  "CNY",
  "EUR",
  "GBP",
  "HKD",
  "SGD",
  "THB",
  "USD",
];

const termsOfPayment = [
  "20%/80%",
  "30%/70%",
  "40%/60%",
  "50%/50%",
  "100%",
  "Other",
]

module.exports = {
  requestorLocation,
  requestorTeam,
  shippingMethod,
  deliveryTerm,
  projectName,
  shippingVia,
  paymentMethod,
  itemCurrency,
  termsOfPayment,
  itemCategory,
}
