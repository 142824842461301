import {
    Box,
    Grid,
    Table,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from "@mui/material";

import { Converter } from "any-number-to-words";
import React from "react";

function TestItemCal(props) {
    const { itemArray, optionalInfo, exchangeRate, shippingObject} = props;
    const converter = new Converter();

    const style = {
        color: "black",
        borderColor: "black",
        padding: 0,
        border: 0.5,
    };

    const toCommas = (number) =>
        number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    const subTotal = () => {
        let subTotal = 0;

        for (let i of itemArray) {
            subTotal +=
                parseFloat(i.itemUnitPrice) * parseFloat(i.itemQuantity);
        }

        return toCommas(subTotal.toFixed(2));
    };

    const discountTotal = () => {
        let discountTotal = 0;

        if (optionalInfo.allDiscount) {
            discountTotal += parseFloat(optionalInfo.allDiscount);
        }

        return toCommas(discountTotal.toFixed(2));
    };

    const vatTotal = () => {
        let amount = 0;
        let vatTotal = 0;
        let vat = parseFloat(optionalInfo.vat);

        for (let i of itemArray) {
            amount = parseFloat(i.itemUnitPrice) * parseFloat(i.itemQuantity);

            if (vat) {
                vatTotal += (amount * (vat || 0)) / 100;
            }
        }

        return toCommas(vatTotal.toFixed(2));
    };

    const grandTotal = () => {
        let amount = 0;
        let grandTotal = 0;
        const allDiscount = parseFloat(optionalInfo.allDiscount);
        const adjustments = parseFloat(optionalInfo.adjustments);
        const vat = parseFloat(optionalInfo.vat);

        for (let i of itemArray) {
            amount += parseFloat(i.itemUnitPrice) * parseFloat(i.itemQuantity);
        }

        if (allDiscount || adjustments || vat) {
            grandTotal += amount - (allDiscount || 0);
            grandTotal += (amount * (vat || 0)) / 100;
        } else grandTotal += amount;

        return grandTotal;
    };

    const currencyName = ()=>{
        const currency = shippingObject.currency
        const currNameDict = { 
            "THB":"Baht",
            "USD":"United States Dollars",
            "GBP":"British Pound Sterling",
            "SGD":"Singaporean Dollars",
            "HKD":"Hong Kong Dollars",
            "EUR":"Euro",
            "CNY":"Chinese Yuan",
            "AUD":"Australian Dollars", 
        }
        return currNameDict[currency]
    }

    const estimatedTotal = () => {
        let amount = 0;
        let estimatedTotal = 0;
        let currdict = { 
            "THB":1,
            "USD":38,
            "GBP":43,
            "SGD":27,
            "HKD":5,
            "EUR":38,
            "CNY":5,
            "AUD":24, 
        }
        const allDiscount = parseFloat(optionalInfo.allDiscount);
        const adjustments = parseFloat(optionalInfo.adjustments);
        const vat = parseFloat(optionalInfo.vat);
        const shippingFees = parseFloat(optionalInfo.shippingFees);
        const currency = parseFloat(optionalInfo.currency);
        for (let i of itemArray) {
            amount += parseFloat(i.itemQuantity) * parseFloat(i.itemUnitPrice);
        }

        if (allDiscount || adjustments || vat || shippingFees || exchangeRate) {
            estimatedTotal += amount - (allDiscount || 0);
            estimatedTotal += (estimatedTotal * (adjustments || 0)) / 100;
            estimatedTotal += (estimatedTotal * (vat || 0)) / 100;
            estimatedTotal += (shippingFees || 0);
              if (currency in currdict && !exchangeRate){
                estimatedTotal *= parseFloat(currdict[currency] || 0)
            } else if (exchangeRate) {
                estimatedTotal *= parseFloat(exchangeRate || 1);
            }
        } else estimatedTotal += amount;

        return estimatedTotal;
    };

    const toCapital = (words) => {
        const wordsArray = words.split(" ");

        for (let i = 0; i < wordsArray.length; i++) {
            wordsArray[i] =
                wordsArray[i][0].toUpperCase() + wordsArray[i].substr(1);
        }

        return wordsArray.join(" ");
    };

    return (
        <Box sx={{ width: "1200px", paddingBottom: 10 }}>
            <Grid container spacing={1}>
                <TableContainer sx={{ width: "1200px" }}>
                    <Table>
                        <TableRow>
                            <TableCell
                                sx={{
                                    ...style,
                                    width: "950px",
                                    backgroundColor: "#097969",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        fontWeight: "bold",
                                        paddingLeft: 1,
                                        color: "white",
                                    }}
                                >
                                    Notes and Instructions
                                </Typography>
                            </TableCell>
                            <TableCell sx={{ ...style, width: "130px" }}>
                                <Typography
                                    sx={{ fontSize: 14, paddingLeft: 1 }}
                                >
                                    Sub Total
                                </Typography>
                            </TableCell>
                            {/* -------------- subtotal value -------------- */}
                            <TableCell
                                align="right"
                                sx={{ ...style, width: "120px" }}
                            >
                                <Typography
                                    sx={{ fontSize: 14, paddingRight: 1 }}
                                >
                                    {subTotal()}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            {/* ------------------------------- note ------------------------------- */}
                            <TableCell
                                sx={{
                                    ...style,
                                    width: "950px",
                                    verticalAlign: "top",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        paddingLeft: 1,
                                        paddingRight: 1,
                                    }}
                                >
                                    {optionalInfo.notesAndInstruction}
                                </Typography>
                            </TableCell>
                            <TableCell sx={{ ...style, width: "170px" }}>
                                <Table sx={{ padding: 0 }}>
                                    {/* -------------- discount title -------------- */}
                                    <TableRow>
                                        <TableCell
                                            sx={{ width: "130px", padding: 0, }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: 14,
                                                    paddingLeft: 1,
                                                }}
                                            >
                                                Discount
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    {/* -------------- vat title -------------- */}
                                    <TableRow>
                                        <TableCell
                                            sx={{  width: "130px", padding: 0, borderColor: "black" , }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: 14,
                                                    paddingLeft: 1,
                                                }}
                                            >
                                                VAT
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    {/* -------------- shipping fee title -------------- */}
                                    <TableRow>
                                        <TableCell
                                            sx={{ width: "130px", padding: 0, background:"#e0e0e0"  }}
                                            >
                                            <Typography
                                                sx={{
                                                    fontSize: 14,
                                                    paddingLeft: 1,
                                                }}
                                                >
                                                    Shipping fee
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                                    {/* -------------- adjustments title -------------- */}
                                    <TableRow>
                                        <TableCell
                                            sx={{ width: "130px", padding: 0, background:"#e0e0e0" }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: 14,
                                                    paddingLeft: 1,
                                                }}
                                            >
                                                Adjustments (%)
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    {/* -------------- estimated total title -------------- */}
                                    <TableRow>
                                        <TableCell
                                            sx={{ width: "130px", padding: 0, background:"#e0e0e0" }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: 14,
                                                    paddingLeft: 1,
                                                }}
                                            >
                                                Estimated Total(THB)
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </Table>
                            </TableCell>
                            <TableCell sx={{ ...style, width: "120px" }}>
                                <Table sx={{ padding: 0 }}>
                                    {/* -------------- discount value -------------- */}
                                    <TableRow>
                                        <TableCell
                                            align="right"
                                            sx={{ width: "120px", padding: 0, }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: 14,
                                                    paddingRight: 1,
                                                }}
                                            >
                                                {discountTotal()}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    {/* -------------- vat value -------------- */}
                                    <TableRow>
                                        <TableCell
                                            align="right"
                                            sx={{  width: "130px", padding: 0, borderColor: "black", }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: 14,
                                                    paddingRight: 1,
                                                }}
                                            >
                                                {vatTotal()}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    {/* -------------- shipping fee value -------------- */}
                                    <TableRow>
                                        <TableCell
                                            align="right"
                                            sx={{ width: "120px", padding: 0, background:"#e0e0e0" }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: 14,
                                                    paddingRight: 1,
                                                }}
                                            >
                                                {toCommas(
                                                    Number(
                                                        optionalInfo.shippingFees
                                                    ).toFixed(2)
                                                )}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    {/* -------------- adjustments value -------------- */}
                                    <TableRow>
                                        <TableCell
                                            align="right"
                                            sx={{ width: "120px", padding: 0, background:"#e0e0e0" }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: 14,
                                                    paddingRight: 1,
                                                }}
                                                >
                                                {toCommas(
                                                    Number(
                                                        optionalInfo.adjustments
                                                    ).toFixed(2)
                                                )}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        {/* -------------- estimated total value -------------- */}
                                        <TableCell
                                            align="right"
                                            sx={{ width: "120px", padding: 0, background:"#e0e0e0" }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: 14,
                                                    paddingRight: 1,
                                                }}
                                                >
                                                {toCommas(
                                                    estimatedTotal().toFixed(2)
                                                )}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </Table>
                            </TableCell>
                        </TableRow>
                        {/* -------------------- Grand Total in letters -------------------- */}
                        <TableRow>
                            <TableCell sx={{ ...style, width: "950px" }}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                    }}
                                >
                                    <Typography
                                        sx={{ fontSize: 14, paddingLeft: 1, }}
                                    >
                                        Grand Total:
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            fontWeight: "bold",
                                            paddingLeft: 1,
                                        }}
                                    >
                                        {`${toCapital(
                                            converter.toWords(
                                                Number(
                                                    grandTotal()
                                                ).toFixed(2)
                                            )
                                        )} ${currencyName()}`}
                                    </Typography>
                                </Box>
                            </TableCell>
                            {/* -------------- Grand total in numbers -------------- */}
                            <TableCell sx={{ ...style, width: "130px" }}>
                                <Typography
                                    sx={{ fontSize: 14, paddingLeft: 1, fontWeight:'bold' }}
                                >
                                    Grand Total
                                </Typography>
                            </TableCell>
                            <TableCell
                                align="right"
                                sx={{ ...style, width: "120px" }}
                            >
                                <Typography
                                    sx={{ fontSize: 14, paddingRight: 1, fontWeight:'bold' }}
                                >
                                    {toCommas(
                                        Number(grandTotal()).toFixed(2)
                                    )}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </Table>
                </TableContainer>
            </Grid>
        </Box>
    );
}

export default TestItemCal;
