import {
  ItemInfo,
  PdfPreview,
  ShippingCondition,
  VendorRequestor,
} from "./index";
import { shippingVia } from "./MenuConstants";
import React from "react";
import { v4 as uuid } from "uuid";
export default function Switcher(props) {
  const {
    activeStep,
    vendorList,
    userList,
    vendorObject,
    setVendorObject,
    shippingObject,
    setShippingObject,
    itemValues,
    setItemValues,
    itemArray,
    setItemArray,
    emptyFields,
    setEmptyFields,
    optionalInfo,
    setOptionalInfo,
    retrievedCurrencyObj,
    prNumber,
    prForm,
    setPrForm,
  } = props;
  const newVendorList = vendorList.map((item) => {
    // if (item.phone === "") {
    //     item.phone = "No data";

    //     return { ...item }
    // }

    if (item.address === ("        " || "")) {
      item.address = "No data";

      return { ...item };
    } else return { ...item };
  });
  // These functions are used for step 1 and 2
  // TODO: Make seperate functions for requestor and vendor. Unnecessary code runs in either case
  const handleVendorAutoComplete = (e, newValue) => {
    if (newValue === "test - test") {
      setVendorObject({
        ...vendorObject,
        vendorCode: "test",
        vendorName: "test",
        vendorPhone: "test",
        vendorAddress: "test",
        vendorEmail: "test",
        requestorLocation: "Head Office: No. 1177 Pearl Bangkok Building 12A floor Room number 12A04-05 Phahonyothin Road Phayathai Phayathai Bangkok 10400 Thailand",
        requestorName: "test",
        requestorEmail: "test",
        requestorPhone: "test",
      });
      setShippingObject({
        shippingVia: "test",
        shippingMethod: "Other",
        deliveryTerms: "Door-to-Door",
        deliveryDate: new Date("04/25/2010"),
        projectName: "Factory 00 - Renovation",
        projectPurpose:
          "testtestetsetstestsetsetsetsetsetsetsetsetsetsetstsetset",
        termOfPayment: "test",
        paymentMethod: "Cash",
        delayPenalty: "test",
        defectWarning: "test",
        currency: "THB",
        paymentDue: new Date("12/25/2022"),
      });
      return;
    }
    const vendorData = newVendorList.find((item) => {
      return `${item.code} - ${item.name}` === newValue;
    });
    if (vendorData) {
      setVendorObject({
        ...vendorObject,
        vendorCode: vendorData.code,
        vendorName: vendorData.name,
        vendorPhone: vendorData.phone,
        vendorAddress: vendorData.address,
      });
    }
  };

  const handleRequestorAutoComplete = (e, newValue) => {
    const requestorData = userList.find((item) => {
      return `${item.firstname} ${item.lastname}` === newValue;
    });

    if (requestorData) {
      setVendorObject({
        ...vendorObject,
        requestorName: newValue,
        requestorEmail: requestorData.email,
        requestorPhone: requestorData.phone,
      });
    }
  };

  const handleTermofPaymentAutoComplete = (e, inputValue) => {
    if (inputValue) {
      setShippingObject({
        ...shippingObject,
        termOfPayment: inputValue,
      });
    }
  };

  // inputCurrency is an object so it needs to have the value extracted
  const handleCurrencyAutoComplete = (e, inputCurrency) => {
    const currency = inputCurrency["props"]["value"];
    if (currency in retrievedCurrencyObj) {
      setShippingObject({
        ...shippingObject,
        currency: currency,
        exchangeRate: retrievedCurrencyObj[currency],
      });
    } else if (currency === "THB") {
      setShippingObject({
        ...shippingObject,
        currency: "THB",
        exchangeRate: 1,
      });
    }
  };

  const handleShippingAutoComplete = (name, inputValue) => {
    const shippingViaData = shippingVia.find(
      (item) => item.brand === inputValue
    );

    if (shippingViaData || name === "shippingVia") {
      if (shippingViaData)
        setShippingObject({
          ...shippingObject,
          shippingVia: inputValue,
        });
      else {
        setShippingObject({
          ...shippingObject,
          shippingVia: inputValue,
        });
      }
    } else {
      return false;
    }
  };

  const handleVendorChange = (e) => {
    const { name, value } = e.target;

    setVendorObject({
      ...vendorObject,
      [name]: value,
    });
  };

  const handleShippingChange = (e) => {
    const { name, value } = e.target;

    setShippingObject({ ...shippingObject, [name]: value });
  };

  const handleDeliveryDate = (newValue) => {
    const _newValue = newValue.toString().split(" ");
    const updateDate = `${_newValue[1]} ${_newValue[2]}, ${_newValue[3]}`;

    setShippingObject({
      ...shippingObject,
      deliveryDate: updateDate,
    });
  };

  const handlePaymentDueDate = (newValue) => {
    const _newValue = newValue.toString().split(" ");
    const updateDate = `${_newValue[1]} ${_newValue[2]}, ${_newValue[3]}`;

    setShippingObject({
      ...shippingObject,
      paymentDue: updateDate,
    });
  };
  // These functions are used for step 3
  const handleItemFormChange = (e) => {
    const { name, value } = e.target;

    setItemValues({
      ...itemValues,
      itemId: uuid(),
      [name]: value,
      isDisabled: true,
    });
    setEmptyFields({ ...emptyFields, [name]: "" });
  };

  const handleItemFormSubmit = (e) => {
    e.preventDefault();

    setItemArray([...itemArray, itemValues]);
    setItemValues(emptyFields);
  };

  const handleItemFormEdit = (e, index) => {
    const { name, value } = e.target;
    const remainData = [...itemArray];
    remainData[index][name] = value;

    setItemArray(remainData);
  };

  const handleItemFormDelete = (id) => {
    const remainData = [...itemArray].filter((item) => {
      return id !== item.itemId;
    });

    setItemArray(remainData);
  };

  const handleOptions = (e) => {
    const { name, value } = e.target;

    setOptionalInfo({
      ...optionalInfo,
      [name]: value,
    });
  };

  switch (activeStep) {
    case 1:
      return (
        <VendorRequestor
          values={vendorObject}
          handleVendorAutoComplete={handleVendorAutoComplete}
          handleRequestorAutoComplete={handleRequestorAutoComplete}
          handleChange={handleVendorChange}
          vendorList={vendorList}
          userList={userList}
        />
      );
    case 2:
      return (
        <ShippingCondition
          handleCurrencyAutoComplete={handleCurrencyAutoComplete}
          values={shippingObject}
          handleAutoComplete={handleShippingAutoComplete}
          handleChange={handleShippingChange}
          handleDeliveryDate={handleDeliveryDate}
          handlePaymentDueDate={handlePaymentDueDate}
          handleTermofPaymentAutoComplete={handleTermofPaymentAutoComplete}
          shippingVia={shippingVia}
        />
      );
    case 3:
      return (
        <ItemInfo
          itemValues={itemValues}
          itemArray={itemArray}
          optionalInfo={optionalInfo}
          deliveryTerms={shippingObject.deliveryTerms}
          handleCurrencyAutoComplete={handleCurrencyAutoComplete}
          currency={shippingObject.currency}
          exchangeRate={shippingObject.exchangeRate}
          handleChange={handleItemFormChange}
          handleSubmit={handleItemFormSubmit}
          handleEdit={handleItemFormEdit}
          handleDelete={handleItemFormDelete}
          handleOptions={handleOptions}
        />
      );
    case 4:
      return (
        <PdfPreview
          vendorObject={vendorObject}
          shippingObject={shippingObject}
          itemArray={itemArray}
          optionalInfo={optionalInfo}
          exchangeRate={shippingObject.exchangeRate}
          prNumber={prNumber}
          prForm={prForm}
          setPrForm={setPrForm}
        />
      );
    default:
  }
}
