import "./pdf/ProcurementPDF.css";
import "./index.css";

import ApprovedPart from "./pdf/ApprovedPart.js";
import ItemInfoTable from "./pdf/ItemInfoTable.js";
import { Paper } from "@mui/material";
import ProcurementHeader from "./pdf/ProcurementHeader.js";
import React from "react";
import ShippingCondition from "./pdf/ShippingCondition.js";
import ShippingTable from "./pdf/ShippingTable.js";
import VendorReqTable from "./pdf/VendorReqTable.js";
import TestItemCal from "./pdf/TestItemCal";

function PdfPreview(props) {
  const {
    vendorObject,
    shippingObject,
    itemArray,
    optionalInfo,
    exchangeRate,
    prNumber,
    prForm,
    setPrForm,
  } = props;

  return (
    <div className="paperbox-wrapper">
      <Paper id="pdf" sx={{ backgroundColor: "#fff" }}>
        <ProcurementHeader
          optionalInfo={optionalInfo}
          prNumber={prNumber}
          prForm={prForm}
          setPrForm={setPrForm}
        />
        <VendorReqTable vendorObject={vendorObject} />
        <ShippingTable shippingObject={shippingObject} />
        <ShippingCondition
          shippingObject={shippingObject}
          exchangeRate={exchangeRate}
        />
        <ItemInfoTable itemArray={itemArray} />
        <TestItemCal
          itemArray={itemArray}
          optionalInfo={optionalInfo}
          exchangeRate={exchangeRate}
          shippingObject = {shippingObject}
        />
        <ApprovedPart />
      </Paper>
    </div>
  );
}

export default PdfPreview;
