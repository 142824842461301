import * as React from "react";
import {
  Box,
  Button,
  IconButton,
  SwipeableDrawer,
  Toolbar,
  Divider,
  Typography,
  Avatar,
} from "@mui/material";
import { Menu, NavigateNext } from "@mui/icons-material";
import MenuDrawerList from "./MenuDrawerList.js";
import { Link } from "react-router-dom";

export default function MenuDrawer({ user }) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const drawerlist = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 310,
        height: "100vh",
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, true)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Toolbar sx={{ display: "flex", pl: 1.5, pr: 1.5 }}>
        <Box
          sx={{
            pt: 2,
            pb: 2,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            textAlign: "left",
          }}
        >
          <Avatar
            alt="profile-pic"
            src={user.photoURL}
            sx={{ width: 56, height: 56 }}
          />
          <Box>
            <Typography
              sx={{
                pl: 2,
                fontFamily: "Montserrat",
                fontWeight: "bold",
                fontSize: 16,
                color: "text.black",
              }}
            >
              {user.displayName}
            </Typography>
          </Box>
          <IconButton component={Link} to={`/account`} sx={{ pl: 3 }}>
            <NavigateNext sx={{ display: "flex" }} justify="flex-end" />
          </IconButton>
        </Box>
      </Toolbar>
      <Divider />
      <MenuDrawerList />
    </Box>
  );

  return (
    <Box sx={{ flexGrow: 0 }}>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={toggleDrawer(anchor, true)}
            color="inherit"
          >
            <Menu />
          </IconButton>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
            disableBackdropTransition={true}
            sx={{ display: { md: "none", xl: "none", lg: "none" } }}
          >
            {drawerlist(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </Box>
  );
}
