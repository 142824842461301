import "./index.css";

import { Add, ArrowUpward } from "@mui/icons-material";
import {
  Button,
  FormControl,
  FormControlLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Item, TotalAmount } from "./index";

import React from "react";
import { itemCategory } from "./MenuConstants";

export default function ItemInfo(props) {
  const {
    itemValues,
    itemArray,
    optionalInfo,
    deliveryTerms,
    currency,
    exchangeRate,
    handleChange,
    handleSubmit,
    handleEdit,
    handleDelete,
    handleOptions,
  } = props;
  const [visible, setVisible] = React.useState(false);
  const mediaQuery = useMediaQuery("(min-width: 900px)");
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;

    if (scrolled > 400) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };
  const scrollTop = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };
  window.addEventListener("scroll", toggleVisible);

  const toggleDisable = () => {
    return !(
      itemValues.itemName &&
      itemValues.itemDescription &&
      itemValues.itemCategory &&
      itemValues.itemQuantity &&
      itemValues.itemUnitPrice
    );
  };
  return (
    <div className="flexbox-wrapper wide-element">
      <div className="flex-column narrow-element flex-auto">
        <Paper elevation={3}>
          <form onSubmit={handleSubmit} className="flexbox-wrapper container">
            <Typography variant={mediaQuery ? "h5" : "h6"}>
              Item Information Form
            </Typography>

            <section className="flexbox-wrapper wide-element">
              <TextField
                required
                multiline
                disabled={itemArray.length === 18}
                size="small"
                variant="outlined"
                label="Name"
                name="itemName"
                className="flex-px"
                value={itemValues.itemName || ""}
                onChange={handleChange}
              />
            </section>

            <section className="flexbox-wrapper wide-element">
              <TextField
                required
                multiline
                disabled={itemArray.length === 18}
                size="small"
                variant="outlined"
                label="Description"
                name="itemDescription"
                className="flex-px"
                value={itemValues.itemDescription || ""}
                onChange={handleChange}
              />
            </section>

            <section className="flexbox-wrapper wide-element">
              <TextField
                select
                required
                disabled={itemArray.length === 20}
                size="small"
                variant="outlined"
                label="Category"
                name="itemCategory"
                style={{ flex: "1 1 250px" }}
                helperText={
                  (itemValues.itemCategory === "IT Equipment and Machine" && (
                    <Typography variant="caption">
                      Price Over 3,000 B. and Use full life of more than 1 Year)
                      ex. Notebook, TV, Monitor, Network, CCTV
                    </Typography>
                  )) ||
                  (itemValues.itemCategory ===
                    "Office Equipment and Furniture" && (
                    <Typography variant="caption">
                      Office Equipment and Furniture: (Price Over 3,000 B. and
                      Use full life of more than 1 Year) ex. shelf, Fire
                      Extinguishers, A/C, Partition, Whiteboard, Paper Shredder,
                      Copy Machine,Table, Cabinet, Fan, fridge,
                    </Typography>
                  )) ||
                  (itemValues.itemCategory === "Software" && (
                    <Typography variant="caption">
                      Price Over 3,000 B. and 2. Use full life of more than 1
                      Year) ex. Oscilloscope software, Acrobat Professional 2017
                      Multiple Platforms, Windows10Pro 32/64 bits
                    </Typography>
                  )) ||
                  (itemValues.itemCategory ===
                    "Engineer Equipment and Machine" && (
                    <Typography variant="caption">
                      Price Over 3,000 B. and Use full life of more than 1
                      Year): ex. PC Oscilloscope, Tool Hand Crimper, Orion B16,
                      Union Bonnet Needle Valve, turning cutting tools set,
                      654-MWDM2L9SCBRP.110, QUICK 201B Desoldering Station 90W
                    </Typography>
                  )) ||
                  (itemValues.itemCategory === "Vehicle" && (
                    <Typography variant="caption">
                      Price Over 3,000 B. and 2. Use full life of more than 1
                      Year) ex. Car, Golf cart, ATV Car
                    </Typography>
                  )) ||
                  (itemValues.itemCategory === "Material" && (
                    <Typography variant="caption">
                      1. Price Below 3,000 B. or 2. Use full life below 1 Year
                      ex. Mouse, steel box, partition NSB, Plug, 21w metal
                      solder bucket micro-D plug HS
                    </Typography>
                  )) ||
                  (itemValues.itemCategory === "Expense" && (
                    <Typography variant="caption">
                      Transportation, Rental, Employee Benefits, Service
                    </Typography>
                  ))
                }
                value={itemValues.itemCategory || ""}
                onChange={handleChange}
              >
                <MenuItem value="IT Equipment and Machine">
                  {" "}
                  IT Equipment and Machine
                </MenuItem>
                <MenuItem value="Engineer Equipment and Machine">
                  Engineer Equipment and Machine
                </MenuItem>
                <MenuItem value="Office Equipment and Furniture">
                  Office Equipment and Furniture
                </MenuItem>
                <MenuItem value="Vehicle">Vehicle</MenuItem>
                <MenuItem value="Software">Software</MenuItem>
                <MenuItem value="Material">Material</MenuItem>
                <MenuItem value="Expense">Expense</MenuItem>
              </TextField>
              <TextField
                required
                disabled={itemArray.length === 18}
                size="small"
                type="number"
                variant="outlined"
                label="Quantity"
                name="itemQuantity"
                className="flex-px"
                InputProps={{ inputProps: { min: 1 } }}
                value={itemValues.itemQuantity || ""}
                onChange={handleChange}
              />
              <TextField
                required
                disabled={itemArray.length === 18}
                size="small"
                variant="outlined"
                label="Unit Price"
                name="itemUnitPrice"
                className="flex-px"
                value={itemValues.itemUnitPrice || ""}
                onChange={handleChange}
                type="number"
                InputProps={{ pattern: "[0-9]*" }}
              />
              {mediaQuery ? (
                <Button
                  disabled={toggleDisable()}
                  variant="contained"
                  color="primary"
                  startIcon={<Add />}
                  type="submit"
                  className="flex-px"
                >
                  {itemArray.length > 18
                    ? "Forms are limited up to 18 items"
                    : "Add"}
                </Button>
              ) : (
                <Button
                  disabled={toggleDisable()}
                  variant="contained"
                  color="primary"
                  type="submit"
                  className="flex-px"
                >
                  <Add />
                </Button>
              )}
            </section>
          </form>
        </Paper>

        <Paper elevation={3} className="flexbox-wrapper container">
          <section className="flexbox-wrapper wide-element">
            <TextField
              size="small"
              type="number"
              variant="outlined"
              label="Discount"
              name="allDiscount"
              className="flex-px"
              value={optionalInfo.allDiscount || ""}
              onChange={handleOptions}
            />
            <TextField
              select
              required
              size="small"
              variant="outlined"
              label="Location"
              name="location"
              helperText={
                optionalInfo.location === "Domestic" && (
                  <Typography variant="caption">
                    <strong>Domestic</strong> has an effect on Adjustments
                  </Typography>
                )
              }
              className="flex-px"
              value={optionalInfo.location || ""}
              onChange={handleOptions}
            >
              <MenuItem value="Domestic">Domestic</MenuItem>
              <MenuItem value="Overseas">Overseas</MenuItem>
            </TextField>
            <TextField
              required
              disabled={
                optionalInfo.location === "Domestic" || deliveryTerms === "DDP"
              }
              size="small"
              variant="outlined"
              type="number"
              label="Adjustments (%)"
              name="adjustments"
              className="flex-px"
              value={
                optionalInfo.location === "Domestic" || deliveryTerms === "DDP"
                  ? (optionalInfo.adjustments = "0")
                  : optionalInfo.adjustments || ""
              }
              onChange={handleOptions}
            />
            <FormControl>
              <RadioGroup
                row
                name="vat"
                className="flex-auto"
                value={optionalInfo.vat || 0}
                onChange={handleOptions}
              >
                <FormControlLabel
                  value={7}
                  control={<Radio size="small" />}
                  label="VAT (7%)"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value={0}
                  control={<Radio size="small" />}
                  label="Non-VAT"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
            <TextField
              required
              size="small"
              type="number"
              variant="outlined"
              label="Shipping Fees"
              name="shippingFees"
              className="flex-auto"
              value={optionalInfo.shippingFees || ""}
              onChange={handleOptions}
            />
          </section>

          <section className="flexbox-wrapper wide-element">
            <TextField
              multiline
              inputProps={{ maxLength: 100 }}
              size="small"
              variant="outlined"
              label="Notes and Instructions"
              name="notesAndInstruction"
              className="flex-px"
              value={optionalInfo.notesAndInstruction || ""}
              onChange={handleOptions}
            />
          </section>

          <section className="flexbox-wrapper wide-element">
            <TotalAmount
              itemArray={itemArray}
              allDiscount={optionalInfo.allDiscount}
              adjustments={optionalInfo.adjustments}
              vat={optionalInfo.vat}
              shippingFees={optionalInfo.shippingFees}
              currency={currency}
              exchangeRate={exchangeRate}
            />
          </section>
        </Paper>

        {visible && (
          <div className="position-sticky">
            <Button
              fullWidth
              variant="contained"
              color="primary"
              startIcon={<ArrowUpward />}
              onClick={scrollTop}
            >
              Scroll To Top
            </Button>
          </div>
        )}
      </div>

      <div className="narrow-element flex-auto">
        {itemArray.map((item, index) => (
          <Item
            key={index}
            index={index}
            item={item}
            currency={currency}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        ))}
      </div>
    </div>
  );
}
