import "./index.css";

import { Card, Typography, useMediaQuery } from "@mui/material";

import React from "react";

export default function TotalAmount(props) {
    const {
        itemArray,
        allDiscount,
        adjustments,
        vat,
        shippingFees,
        currency,
        exchangeRate,
    } = props;
    const mediaQuery = useMediaQuery("(min-width: 900px)");

    const toCommas = (number) =>
        number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    const subTotal = () => {
        let amount = 0;

        for (let i of itemArray) {
            amount += parseFloat(i.itemQuantity) * parseFloat(i.itemUnitPrice);
        }

        return toCommas(amount.toFixed(2));
    };

    const discountTotal = () => {
        let discount = 0;

        if (allDiscount) {
            discount += parseFloat(allDiscount);
        }

        return toCommas(discount.toFixed(2));
    };

    const vatTotal = () => {
        let amount = 0;
        let vatTotal = 0;

        for (let i of itemArray) {
            amount += parseFloat(i.itemQuantity) * parseFloat(i.itemUnitPrice);
        }

        if (vat) {
            vatTotal = (amount * parseFloat(vat || 0)) / 100;
        }

        return toCommas(vatTotal.toFixed(2));
    };

    const grandTotal = () => {
        let amount = 0;
        let grandTotal = 0;

        for (let i of itemArray) {
            amount += parseFloat(i.itemQuantity) * parseFloat(i.itemUnitPrice);
        }

        if (allDiscount || adjustments || vat) {
            grandTotal += amount - parseFloat(allDiscount || 0);
            grandTotal += (amount * parseFloat(vat || 0)) / 100;
        } else grandTotal += amount;

        return toCommas(grandTotal.toFixed(2));
    };

    const estimatedTotal = () => {
        let amount = 0;
        let estimatedTotal = 0;
        let currdict = { 
            "THB":1,
            "USD":38,
            "GBP":43,
            "SGD":27,
            "HKD":5,
            "EUR":38,
            "CNY":5,
            "AUD":24, 
            }

        for (let i of itemArray) {
            amount += parseFloat(i.itemQuantity) * parseFloat(i.itemUnitPrice);
        }

        if (allDiscount || adjustments || vat || shippingFees || exchangeRate) {
            estimatedTotal += amount - parseFloat(allDiscount || 0);
            estimatedTotal += (estimatedTotal * parseFloat(adjustments || 0)) / 100;
            estimatedTotal += (estimatedTotal * parseFloat(vat || 0)) / 100;
            estimatedTotal += parseFloat(shippingFees || 0);
            if (currency in currdict && !exchangeRate){
                estimatedTotal *= parseFloat(currdict[currency] || 0)
            } else if (exchangeRate) {
                estimatedTotal *= parseFloat(exchangeRate || 1);
            }
        } else estimatedTotal += amount;
        
        return toCommas(estimatedTotal.toFixed(2));
    };

    return (
        <>
            <Card variant="outlined" className="amount-element">
                <Typography style={{ textAlign: "center" }}>Sub Total</Typography>

                <Typography style={{ fontWeight: "bold", textAlign: "center" }}>
                    {subTotal()}{" "}
                    {currency}
                </Typography>
            </Card>

            <Card
                variant="outlined"
                className="amount-element"
                style={{ flex: "1 1 200px" }}
            >
                <Typography style={{ textAlign: "center" }}>
                    Discount Total
                </Typography>

                <Typography style={{ fontWeight: "bold", textAlign: "center" }}>
                    {discountTotal() !== 0 ? discountTotal() : 1} {currency}
                </Typography>
            </Card>

            <Card
                variant="outlined"
                className="amount-element"
                style={{ flex: "1 1 200px" }}
            >
                <Typography style={{ textAlign: "center" }}>
                    VAT Total
                </Typography>

                <Typography style={{ fontWeight: "bold", textAlign: "center" }}>
                    {vatTotal() !== 0 ? vatTotal() : 0} {currency}
                </Typography>
            </Card>

            <Card variant="outlined" className="amount-element">
                <Typography style={{ textAlign: "center" }}>
                    {mediaQuery ? "Grand Total" : null}
                </Typography>

                <Typography style={{ fontWeight: "bold", textAlign: "center" }}>
                    {grandTotal() !== 0 ? grandTotal() : 0} {currency}
                </Typography>
            </Card>

            <Card variant="outlined" className="amount-element">
                <Typography style={{ textAlign: "center" }}>
                    Estimated Total
                </Typography>

                <Typography style={{ fontWeight: "bold", textAlign: "center" }}>
                    {estimatedTotal() !== 0 ? estimatedTotal() : 0} THB
                </Typography>
            </Card>
        </>
    );
}
