import { Box } from "@mui/material";
import React from "react";
import MuspaceLogo from "../../assets/logo/mu_logo_white.png";
import LoginButton from "../../components/LoginButton/LoginButton.js";

function CheckUser() {
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        bgcolor: "black",
      }}
    >
      <Box
        component="img"
        sx={{
          pb: 2,
          height: { xs: 150, sm: 200, md: 300 },
        }}
        alt=""
        src={MuspaceLogo}
      ></Box>
      <LoginButton />
    </Box>
  );
}

export default CheckUser;
