import React from "react";
import { Box, Typography, Grid, Divider, useTheme } from "@mui/material";
import pearl from "../../assets/etc/pearl.jpg";
import LocationCard from "./LocationCard";
import LocationText from "./LocationText";
import LocationCardItems from "./LocationCardItem";

function AboutContent() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        // background: theme.palette.background.dashboardGradient,
        background: 'black',
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        py: 5,
        px: { xs: 3, sm: 5, md: 10, xl: 20 },
      }}
    >
      <Typography fontWeight="bold" fontSize={35} color='text.white' sx={{ paddingBottom: 5 }}>
        How To Reach Us
      </Typography>
      <LocationText />
      <Divider variant="middle" sx={{ width: '100%', paddingBottom: 4, borderBottomWidth: 3 }}></Divider>
      <Typography
        color='text.white'
        sx={{
          fontWeight: "bold",
          fontSize: 35,
          paddingY: 5,
        }}
      >
        Locations
      </Typography>
      <Grid
        container
        rowSpacing={{ xs: 3, sm: 3 }}
        columnSpacing={{ xs: 1, sm: 2, lg: 4 }}
      >
        {LocationCardItems.map((location) => {
          return (
            <LocationCard
              key={location.id}
              image={location.image}
              title={location.title}
              address={location.address}
              route={location.route}
            />
          );
        })}
      </Grid>
    </Box >
  );
}

export default AboutContent;
