import axios from "axios"
export async function fetchCurrencyList() {
    let returnData = {}
    await axios
    .get("https://portal.muspacecorp.com/api/v1/getallvalues", {
        headers: {
            "x-api-key" : process.env.REACT_APP_CURRENCY_API_KEY
        }
    })
    .then((res) => {
        returnData = res.data
    })
    .catch((err) => {
        console.log(err)
    })
    return returnData;
}

export default fetchCurrencyList;