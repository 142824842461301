import React from "react";
import { Box, Typography, Grid, Paper } from "@mui/material";

function LocationText() {
  return (
    <Paper
      elevation={5}
      sx={{
        padding: 3, background: "white", minWidth: '40%', maxWidth: '800px'
      }}>
      <Grid container columnSpacing={5} rowSpacing={2} >
        <Grid item md={6} sm={12} >
          <Typography sx={{ fontWeight: "bold", fontSize: 20 }}>
            Head Office
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            Phone: +662 028 9425
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            E-mail: info@muspacecorp.com
          </Typography>
        </Grid>
        <Grid item md={6} sm={12} >
          <Typography sx={{ fontWeight: "bold", fontSize: 20 }}>
            Press
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            Phone: +662 028 9425 ext 60
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            E-mail: press@muspacecorp.com
          </Typography>
        </Grid>
        <Grid item md={6} sm={12} >
          <Typography sx={{ fontWeight: "bold", fontSize: 20 }}>
            Sales
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            Phone: +662 028 9425 ext 21
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            E-mail: sales@muspacecorp.com
          </Typography>
        </Grid>
        <Grid item md={6} sm={12} >
          <Typography sx={{ fontWeight: "bold", fontSize: 20 }}>
            Human Resource
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            Phone: +662 028 9425 ext 74
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            E-mail: hr@muspacecorp.com
          </Typography>
        </Grid>
      </Grid>
    </Paper >
  );
}

export default LocationText;
