import * as React from "react";
import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

function ServiceButtonSM({ label, route, image }) {
  return (
    <Box
      sx={{
        background: "linear-gradient(to right bottom,#abb8cb,#F1F2F6)",
        boxShadow: 5,
        borderRadius: "20px",
        width: "350px",
      }}
    >
      <Button
        component={Link}
        to={`${route}`}
        sx={{
          height: "120px",
          color: "black",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Box
          component="img"
          sx={{
            color: "black",
            display: "flex",
            flexDirection: "column",
            p: 2.5,
            height: 100,
          }}
          alt=""
          src={`${image}`}
        />
        <Typography
          sx={{
            fontSize: { xs: 20, sm: 26 },
            fontFamily: "Montserrat",
            fontWeight: "bold",
            textAlign: "center",
            width: "60%",
          }}
        >
          {label}
        </Typography>
      </Button>
    </Box>
  );
}

export default ServiceButtonSM;
