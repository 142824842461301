import React from "react";
import { Box } from "@mui/material";
import Appbar from "../../components/Appbar/Appbar";
import AboutContent from "./AboutContent";

function About({ user }) {
  return (
    <>
      <Appbar user={user} />
      <AboutContent />
    </>
  );
}

export default About;
