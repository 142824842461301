import React from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import DashboardItems from "./DashboardItems.js";
import GridLayoutButton from "../../../components/GridLayoutButton/GridLayoutButton.js";

function DashboardContent() {
  return (
    <Box
      sx={{
        py: 5,
        px: { xs: 3, sm: 5, md: 10, xl: 20 },
      }}
    >
      <Typography
        variant="h2"
        color="text.white"
        bgcolor="black"
        align="center"
        textTransform={'uppercase'}
        sx={{ fontFamily: "Montserrat", fontWeight: "bold" }}
      >
        Portal Web
      </Typography>
      <Divider
        variant="fullWidth"
        orientation="horizontal"
        sx={{ my: 6, bgcolor: "text.white" }}
      />
      <Grid
        container
        rowSpacing={{ xs: 3, sm: 10 }}
        columnSpacing={{ xs: 1, sm: 2, lg: 4 }}
      >
        {DashboardItems.map((service) => {
          return <GridLayoutButton key={service.id} service={service} />;
        })}
      </Grid>
    </Box>
  );
}

export default DashboardContent;
