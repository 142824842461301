import shoppingIcon from "../../assets/icons/shoppingIcon.png";

const FormItems = [
  {
    id: 0,
    image: shoppingIcon,
    label: "Purchase Requisition",
    route: "procurement",
  },

];

export default FormItems;
