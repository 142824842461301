import React from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";

function ApprovedPart() {
  return (
    <Box sx={{ width: "1200px" }}>
      <Grid container spacing={1}>
        {/* -------------------------------- requested by -------------------------------- */}
        <Grid width="600px">
          <Grid container sx={{ paddingBottom: 5 }}>
            <Grid width="50px" />
            <Grid width="150px">
              <Typography sx={{ fontSize: 14 }}>REQUESTED BY</Typography>
            </Grid>
            <Grid width="350px">
              <Box sx={{ paddingBottom: 3 }}></Box>
              <Divider sx={{ backgroundColor: "black" }}></Divider>
            </Grid>
            <Grid width="50px"></Grid>
          </Grid>
          {/* -------------------------------- approved box -------------------------------- */}
          <Grid container>
            <Grid width="550px">
              <Box sx={{ border: 1, paddingLeft: 1 }}>
                <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
                  Authorized Approver Instruction:
                </Typography>
                <Typography sx={{ fontSize: 14 }}>
                  1. Team lead + Finance for expense less than 50,000 Baht
                </Typography>
                <Typography sx={{ fontSize: 14 }}>
                  2. Team lead + Finance + C-Level expenses less than 500,000 Baht
                </Typography>
                <Typography sx={{ fontSize: 14 }}>
                  3. Team lead + Finance + C-Level for expenses more than
                  500,000 Baht
                </Typography>
              </Box>
            </Grid>
            <Grid width="50px"></Grid>
          </Grid>
        </Grid>
        <Grid width="600px">
          {/* -------------------------------- approved sign -------------------------------- */}
          {/* ----- supervisor ----- */}
          <Grid container sx={{ paddingBottom: 8 }}>
            <Grid width="150px">
              <Typography sx={{ fontSize: 14 }}>APPROVED BY</Typography>
            </Grid>
            <Grid width="350px">
              <Box sx={{ paddingBottom: 3 }}></Box>
              <Divider sx={{ backgroundColor: "black" }}></Divider>
              <Typography sx={{ fontSize: 14, textAlign: "center" }}>
                (Supervisor)
              </Typography>
            </Grid>
            <Grid width="100px"></Grid>
          </Grid>
          {/* ----- finance team ----- */}
          <Grid container sx={{ paddingBottom: 8 }}>
            <Grid width="150px">
              <Typography sx={{ fontSize: 14 }}>APPROVED BY</Typography>
            </Grid>
            <Grid width="350px">
              <Box sx={{ paddingBottom: 3 }}></Box>
              <Divider sx={{ backgroundColor: "black" }}></Divider>
              <Typography sx={{ fontSize: 14, textAlign: "center" }}>
                (Finance Team)
              </Typography>
            </Grid>
            <Grid width="100px"></Grid>
          </Grid>
          {/* ----- c-level ----- */}
          <Grid container>
            <Grid width="150px">
              <Typography sx={{ fontSize: 14 }}>APPROVED BY</Typography>
            </Grid>
            <Grid width="350px">
              <Box sx={{ paddingBottom: 3 }}></Box>
              <Divider sx={{ backgroundColor: "black" }}></Divider>
              <Typography sx={{ fontSize: 14, textAlign: "center" }}>
                (C-Level)
              </Typography>
            </Grid>
            <Grid width="100px"></Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ApprovedPart;
