const InitialItem = [
  {
    id: 0,
    itemName: "",
    itemDescription: "",
    itemQuantity: "",
    itemCategory: "",
    itemVat: "",
    itemUnitPrice: "",
    itemDiscount: "",
    itemCurrency: "",
    itemAmount: "",
  },
  {
    id: 1,
    itemName: "",
    itemDescription: "",
    itemQuantity: "",
    itemCategory: "",
    itemVat: "",
    itemUnitPrice: "",
    itemDiscount: "",
    itemCurrency: "",
    itemAmount: "",
  },
  {
    id: 2,
    itemName: "",
    itemDescription: "",
    itemQuantity: "",
    itemCategory: "",
    itemWithholding: "",
    itemUnitPrice: "",
    itemDiscount: "",
    itemCurrency: "",
    itemAmount: "",
  },
  {
    id: 3,
    itemName: "",
    itemDescription: "",
    itemQuantity: "",
    itemCategory: "",
    itemWithholding: "",
    itemUnitPrice: "",
    itemDiscount: "",
    itemCurrency: "",
    itemAmount: "",
  },
  {
    id: 4,
    itemName: "",
    itemDescription: "",
    itemQuantity: "",
    itemCategory: "",
    itemWithholding: "",
    itemUnitPrice: "",
    itemDiscount: "",
    itemCurrency: "",
    itemAmount: "",
  },
  {
    id: 5,
    itemName: "",
    itemDescription: "",
    itemQuantity: "",
    itemCategory: "",
    itemWithholding: "",
    itemUnitPrice: "",
    itemDiscount: "",
    itemCurrency: "",
    itemAmount: "",
  },
  {
    id: 6,
    itemName: "",
    itemDescription: "",
    itemQuantity: "",
    itemCategory: "",
    itemWithholding: "",
    itemUnitPrice: "",
    itemDiscount: "",
    itemCurrency: "",
    itemAmount: "",
  },
  {
    id: 7,
    itemName: "",
    itemDescription: "",
    itemQuantity: "",
    itemCategory: "",
    itemWithholding: "",
    itemUnitPrice: "",
    itemDiscount: "",
    itemCurrency: "",
    itemAmount: "",
  },
  {
    id: 8,
    itemName: "",
    itemDescription: "",
    itemQuantity: "",
    itemCategory: "",
    itemWithholding: "",
    itemUnitPrice: "",
    itemDiscount: "",
    itemCurrency: "",
    itemAmount: "",
  },
  {
    id: 9,
    itemName: "",
    itemDescription: "",
    itemQuantity: "",
    itemCategory: "",
    itemWithholding: "",
    itemUnitPrice: "",
    itemDiscount: "",
    itemCurrency: "",
    itemAmount: "",
  },
  {
    id: 10,
    itemName: "",
    itemDescription: "",
    itemQuantity: "",
    itemCategory: "",
    itemWithholding: "",
    itemUnitPrice: "",
    itemDiscount: "",
    itemCurrency: "",
    itemAmount: "",
  },
  {
    id: 11,
    itemName: "",
    itemDescription: "",
    itemQuantity: "",
    itemCategory: "",
    itemWithholding: "",
    itemUnitPrice: "",
    itemDiscount: "",
    itemCurrency: "",
    itemAmount: "",
  },
  {
    id: 12,
    itemName: "",
    itemDescription: "",
    itemQuantity: "",
    itemCategory: "",
    itemWithholding: "",
    itemUnitPrice: "",
    itemDiscount: "",
    itemCurrency: "",
    itemAmount: "",
  },
  {
    id: 13,
    itemName: "",
    itemDescription: "",
    itemQuantity: "",
    itemCategory: "",
    itemWithholding: "",
    itemUnitPrice: "",
    itemDiscount: "",
    itemCurrency: "",
    itemAmount: "",
  },
  {
    id: 14,
    itemName: "",
    itemDescription: "",
    itemQuantity: "",
    itemCategory: "",
    itemWithholding: "",
    itemUnitPrice: "",
    itemDiscount: "",
    itemCurrency: "",
    itemAmount: "",
  },
  {
    id: 15,
    itemName: "",
    itemDescription: "",
    itemQuantity: "",
    itemCategory: "",
    itemWithholding: "",
    itemUnitPrice: "",
    itemDiscount: "",
    itemCurrency: "",
    itemAmount: "",
  },
   {
    id: 16,
    itemName: "",
    itemDescription: "",
    itemQuantity: "",
    itemCategory: "",
    itemWithholding: "",
    itemUnitPrice: "",
    itemDiscount: "",
    itemCurrency: "",
    itemAmount: "",
  },
   {
    id: 17,
    itemName: "",
    itemDescription: "",
    itemQuantity: "",
    itemCategory: "",
    itemWithholding: "",
    itemUnitPrice: "",
    itemDiscount: "",
    itemCurrency: "",
    itemAmount: "",
  },
   {
    id: 18,
    itemName: "",
    itemDescription: "",
    itemQuantity: "",
    itemCategory: "",
    itemWithholding: "",
    itemUnitPrice: "",
    itemDiscount: "",
    itemCurrency: "",
    itemAmount: "",
  },
];

export default InitialItem;
