import React from "react";
import Appbar from "../../components/Appbar/Appbar";
import { Box, Typography } from "@mui/material";

function MyAccount({ user }) {
  return (
    <Box
      sx={{
        height: "100vh",
        bgcolor: "background.dashboard",
      }}
    >
      <Appbar user={user} />
      <Typography>my account page</Typography>
    </Box>
  );
}

export default MyAccount;
