import axios from "axios";
export async function fetchVendorList() {
  let returnData = {};
  await axios
    .get("https://portal.muspacecorp.com/vendors", {
      headers: {
        apikey: process.env.REACT_APP_SAILS_API_KEY,
      },
    })
    .then((res) => {
      returnData = res.data;
    })
    .catch((err) => {
      console.log(err);
    });
  return returnData;
}

export async function fetchUserList() {
  let returnData = {};
  await axios
    .get("https://portal.muspacecorp.com/users", {
      headers: {
        apikey: process.env.REACT_APP_SAILS_API_KEY,
      },
    })
    .then((res) => {
      returnData = res.data;
    })
    .catch((err) => {
      console.log(err);
    });
  return returnData;
}
export async function fetchPrNumber(location) {
  let returnData = "";
  await axios
    .get(
      `https://portal.muspacecorp.com/procurement/pr-count?monthCode=MUS${location}`,
      {
        headers: {
          apikey: process.env.REACT_APP_SAILS_API_KEY,
        },
      }
    )
    .then((res) => {
      let getPR = res.data.count + "";
      while (getPR.length < 4) getPR = "0" + getPR;
      returnData = getPR;
    })
    .catch((err) => {
      console.log(err);
    });
  return returnData;
}
