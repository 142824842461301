import "./Procurement.css";
import "./../../../components/Forms/pdf/ProcurementPDF.css";

import { Container, Grid, LinearProgress, Typography } from "@mui/material";

import Appbar from "../../../components/Appbar/Appbar";
import React from "react";
import Stepper from "../../../components/Stepper/Stepper";
import Switcher from "../../../components/Forms/Switcher";
import axios from "axios";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import fetchCurrencyList from "../../../api/currencyapi";
import {fetchPrNumber, fetchUserList,fetchVendorList} from "../../../api/mongoapi";
const steps = [
  "Vendor & Supplier",
  "Shipping & Condition",
  "Item Information",
  "PDF Generator",
];

export default function Procurement(props) {
  const { user } = props;
  const [activeStep, setActiveStep] = React.useState(1);
  const [vendorObject, setVendorObject] = React.useState({});
  const [shippingObject, setShippingObject] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [vendorList, setVendorList] = React.useState([]);
  const [userList, setUserList] = React.useState([]);

  const [itemValues, setItemValues] = React.useState({});
  const [itemArray, setItemArray] = React.useState([]);
  const [emptyFields, setEmptyFields] = React.useState({});
  const [optionalInfo, setOptionalInfo] = React.useState({});
  const [retrievedCurrencyObj, setCurrencyValues] = React.useState({});
  const [prForm, setPrForm] = React.useState("MUS");
  const [prNumber, setPrNumber] = React.useState("0000");
  const [isPrLoading, setIsPrLoading] = React.useState(true);
  const [sendLoading, setSendLoading] = React.useState(false);

  const delay = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(2);
        setSendLoading(false);
      }, 2000);
    });
  };
  React.useEffect(() => {
    if (activeStep === 4) {
      setIsLoading(true);
      // Have to query how many how many requests there have been depending on location
      //The prID query uses the first letter (either D or O) of location depending on selection
      fetchPrNumber(optionalInfo.location[0]).then((result) => {
        if (result) {
          setPrNumber(result);
        } else {
          console.log("PR number retrival failed")
        }
      })
      setIsLoading(false);
    }
  }, [activeStep, optionalInfo.location]);
  // Only 3 steps to application so fourth is creating the PR Form and sending the request
  const handleNext = async () => {
    if (activeStep === 4) {
      setActiveStep(4);
      setSendLoading(true);
      // Need to avoid the edge case where the api takes longer than 1 second
      if (prForm.substring(0, prForm.length - 4) === "0000") {
        await delay(3000);
      }
      const d = new Date();
      const postData = {
        ...vendorObject,
        ...shippingObject,
        ...optionalInfo,
        madeBy: user.uid,
        url: d.toISOString(),
        prID: prForm,
        items: { ...itemArray },
      };
      console.log(postData);
      const pdf = new jsPDF("portrait", "pt", "a4");
      const data = await html2canvas(document.querySelector("#pdf"), {
        scale: 1.25,
        removeContainer: true,
      });
      const img = data.toDataURL("image/png");
      const imgProp = pdf.getImageProperties(img);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProp.height * pdfWidth) / imgProp.width;
      await axios
        .post("https://portal.muspacecorp.com/procurement", postData, {
          headers: {
            apikey: process.env.REACT_APP_SAILS_API_KEY,
          },
        })
        .then((response) => {
          setSendLoading(false);
          if (response["status"] === 200) {
            pdf.addImage(img, "JPEG", 0, 0, pdfWidth, pdfHeight);
            window.open(pdf.output("bloburl", "test.pdf"), "_blank");
          }
        })
        .catch((error) => {
          alert("Got backend error", error);
          console.log(error);
        });
    } else {
      setActiveStep((prevStep) => prevStep + 1);
      if (shippingObject.projectPurpose === undefined) {
        shippingObject.projectPurpose = "";
      }
      if (optionalInfo.vat === undefined) {
        optionalInfo.vat = 0;
      }
      if (optionalInfo.allDiscount === undefined) {
        optionalInfo.allDiscount = 0;
      }
      if (optionalInfo.shippingFees === undefined) {
        optionalInfo.shippingFees = 0;
      }
      if (shippingObject.termOfPayment === "Other") {
        shippingObject.termOfPayment = shippingObject.customTermOfPayment;
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };
  // Used for loading API's and passing onto switcher for processing
  React.useEffect(() => {
    setIsLoading(true);
    fetchCurrencyList().then((result) => {
      if (result) {
        setCurrencyValues(result);
      } else {
        console.log("CurrencyAPI error");
        console.log("Got", result);
      }
    });
    fetchUserList().then((result) => {
      if (result) {
        setUserList(result);
      } else {
        console.log("Userlist error");
      }
    });
    fetchVendorList().then((result) => {
      if (result) {
        setVendorList(result);
      } else {
        console.log("Vendorlist error");
      }
    });
    setIsLoading(false);
  }, []);
  return (
    <div id="procurement">
      <Appbar user={user} />
      {isLoading ? (
        <LinearProgress
          sx={{
            backgroundColor: "black",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "gray",
            },
          }}
        />
      ) : (
        <div id="content-container-grid">
          <Typography
            variant="h2"
            color="white"
            paddingTop={3}
            paddingBottom={5}
            align="center"
            sx={{
              fontFamily: "Montserrat",
              fontWeight: "bold",
              textTransform: "uppercase",
            }}
          >
            Purchase Requisition
          </Typography>
          <Grid container rowSpacing={2} alignItems="center">
            <Grid item xs={12}>
              <Container disableGutters maxWidth={"xl"}>
                <Stepper
                  steps={steps}
                  activeStep={activeStep}
                  handleNext={handleNext}
                  handleBack={handleBack}
                  vendorObject={vendorObject}
                  shippingObject={shippingObject}
                  optionalInfo={optionalInfo}
                  itemArray={itemArray}
                  sendLoading={sendLoading}
                />
              </Container>
            </Grid>
            <Grid item xs={12}>
              <Container disableGutters maxWidth="xl">
                <Switcher
                  activeStep={activeStep}
                  retrievedCurrencyObj={retrievedCurrencyObj}
                  userList={userList}
                  vendorList={vendorList}
                  setCurrencyValues={setCurrencyValues}
                  vendorObject={vendorObject}
                  setVendorObject={setVendorObject}
                  shippingObject={shippingObject}
                  setShippingObject={setShippingObject}
                  itemValues={itemValues}
                  setItemValues={setItemValues}
                  itemArray={itemArray}
                  setItemArray={setItemArray}
                  emptyFields={emptyFields}
                  setEmptyFields={setEmptyFields}
                  optionalInfo={optionalInfo}
                  setOptionalInfo={setOptionalInfo}
                  prNumber={prNumber}
                  prForm={prForm}
                  setPrForm={setPrForm}
                />
              </Container>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
}
