import React from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";

function LocationCard({ title, image, address, route }) {
  return (
    <Grid item xs={12} sm={6} lg={3}>
      <Card sx={{ height: 500 }}>
        <CardMedia component="img" height="250" image={image} alt="pearl" />
        <CardContent>
          <Grid height="220px">
            <Grid height="180px">
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: 18,
                  paddingBottom: 1,
                }}
              >
                {title}
              </Typography>
              <Typography>{address}</Typography>
            </Grid>
            <Grid height="40px">
              <Button sx={{ backgroundColor: "gray" }} href={route}>
                <Typography sx={{ color: "black" }}>Map</Typography>
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default LocationCard;
