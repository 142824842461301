import "./index.css";

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    MenuItem,
    TextField,
    Typography,
} from "@mui/material";
import { DeleteOutlineOutlined, Edit, ExpandMore } from "@mui/icons-material";

import React from "react";
import { itemCategory } from "./MenuConstants";

export default function Item(props) {
    const { index, item, currency, handleEdit, handleDelete } = props;
    const [disableItem, setDisableItem] = React.useState(item.isDisabled);

    const isDisabled = () => {
        setDisableItem(!disableItem);
    };

    const toCommas = (number) =>
        number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return (
        <Accordion key={index}>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>
                    {item.itemName} - {toCommas(item.itemUnitPrice)} {currency}
                </Typography>
            </AccordionSummary>

            <AccordionDetails>
                <form className="flexbox-wrapper long-element">
                    <section className="flexbox-wrapper wide-element">
                        <TextField
                            multiline
                            disabled={disableItem}
                            size="small"
                            variant="outlined"
                            label="Name"
                            name="itemName"
                            className="flex-px"
                            value={item.itemName}
                            onChange={(e) => handleEdit(e, index)}
                        />
                    </section>

                    <section className="flexbox-wrapper wide-element">
                        <TextField
                            multiline
                            disabled={disableItem}
                            size="small"
                            variant="outlined"
                            label="Description"
                            name="itemDescription"
                            className="flex-px"
                            value={item.itemDescription}
                            onChange={(e) => handleEdit(e, index)}
                        />
                    </section>

                    <section className="flexbox-wrapper wide-element">
                        <TextField
                            select
                            disabled={disableItem}
                            size="small"
                            variant="outlined"
                            label="Category"
                            name="itemCategory"
                            style={{ flex: "1 1 250px" }}
                            value={item.itemCategory}
                            onChange={(e) => handleEdit(e, index)}
                        >
                            {itemCategory.map((item, index) => (
                                <MenuItem key={index} value={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            disabled={disableItem}
                            size="small"
                            type="number"
                            variant="outlined"
                            label="Quantity"
                            name="itemQuantity"
                            className="flex-px"
                            value={item.itemQuantity}
                            onChange={(e) => handleEdit(e, index)}
                        />
                        <TextField
                            disabled={disableItem}
                            size="small"
                            variant="outlined"
                            label="Unit Price"
                            name="itemUnitPrice"
                            className="flex-px"
                            value={item.itemUnitPrice}
                            onChange={(e) => handleEdit(e, index)}
                        />
                        <Button
                            variant={disableItem ? "outlined" : "contained"}
                            color="warning"
                            startIcon={<Edit />}
                            style={{ flex: "1 1 20px" }}
                            onClick={isDisabled}
                        >
                            Edit
                        </Button>
                        <Button
                            variant="outlined"
                            color="error"
                            startIcon={<DeleteOutlineOutlined />}
                            style={{ flex: "1 1 20px" }}
                            onClick={() => handleDelete(item.itemId)}
                        >
                            Delete
                        </Button>
                    </section>
                </form>
            </AccordionDetails>
        </Accordion>
    );
}
