import * as React from "react";
import { Box, Button, Paper, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import './ServiceButton.css'


function ServiceButton({ label, route, image }) {
  return (
    <div id='service-paper'>
      <div className='service-button'>
        <Button
          id='service-button'
          component={Link}
          to={`${route}`}
        >
          <Box
            id='service-imagebox'
            component="img"
            alt=""
            src={`${image}`}
          />
          <Typography
            sx={{
              fontSize: 26,
              fontFamily: "Montserrat",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {label}
          </Typography>
        </Button>
      </div>
    </div >
  );
}

export default ServiceButton;
