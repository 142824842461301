import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import React from "react";

function ShippingTable(props) {
  const { shippingObject } = props;
  const style = {
    color: "black",
    borderColor: "black",
    padding: 0,
    border: 0.5,
  };

  return (
    <Box sx={{ width: "1200px" }}>
      <Grid container spacing={1} sx={{ paddingBottom: 3 }}>
        <TableContainer sx={{ width: "1200px", padding: 0 }}>
          <Table size="small" sx={{ border: 0.5, width: "1200px" }}>
            <TableHead>
              <TableRow>
                {/* -------------------------------- ship via -------------------------------- */}
                <TableCell
                  align="center"
                  sx={{ ...style, fontWeight: "bold", width: "300px" }}
                >
                  <Box sx={{ backgroundColor: "#097969", color: "white" }}>
                    <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                      Ship via
                    </Typography>
                  </Box>
                </TableCell>
                {/* -------------------------------- ship method -------------------------------- */}
                <TableCell
                  align="center"
                  sx={{ ...style, fontWeight: "bold", width: "300px" }}
                >
                  <Box sx={{ backgroundColor: "#097969", color: "white" }}>
                    <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                      Shipping Method
                    </Typography>
                  </Box>
                </TableCell>
                {/* -------------------------------- delivery terms -------------------------------- */}
                <TableCell
                  align="center"
                  sx={{ ...style, fontWeight: "bold", width: "300px" }}
                >
                  <Box sx={{ backgroundColor: "#097969", color: "white" }}>
                    <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                      Delivery Terms
                    </Typography>
                  </Box>
                </TableCell>
                {/* -------------------------------- delivery date/ Expected Delivery -------------------------------- */}
                <TableCell
                  align="center"
                  sx={{ ...style, fontWeight: "bold", width: "300px" }}
                >
                  <Box sx={{ backgroundColor: "#097969", color: "white" }}>
                    <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                      Expected Delivery
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center" sx={{ ...style }}>
                  <Typography sx={{ fontSize: 14 }}>
                    {shippingObject.shippingVia}
                  </Typography>
                </TableCell>
                <TableCell align="center" sx={{ ...style }}>
                  <Typography sx={{ fontSize: 14 }}>
                    {shippingObject.shippingMethod}
                  </Typography>
                </TableCell>
                <TableCell align="center" sx={{ ...style }}>
                  <Typography sx={{ fontSize: 14 }}>
                    {shippingObject.deliveryTerms}
                  </Typography>
                </TableCell>
                <TableCell align="center" sx={{ ...style }}>
                  <Typography sx={{ fontSize: 14 }}>
                    {new Date(shippingObject.deliveryDate).toLocaleDateString()}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Box>
  );
}

export default ShippingTable;
