import * as React from "react";
import { AppBar, Box, Toolbar, Container, useTheme } from "@mui/material";

import AboutButton from "./AppbarComponents/AboutButton";
import MenuButton from "./AppbarComponents/MenuButton/MenuButton";
import MenuDrawer from "./AppbarComponents/MenuDrawer/MenuDrawer";
import ProfilePicture from "./AppbarComponents/ProfilePicture";
import MuLogoWhite from "../../assets/logo/mu_logo_white_no_text.png";
import MuLogoNameWhite from "../../assets/logo/mu_logo_name_white.png";
import { Link } from "react-router-dom";

function Appbar({ user }) {
  return (
    <>
      <AppBar
        position="relative"
        sx={{
          // background: theme.palette.background.appbarGradient,
          background: '#00000F',
          boxShadow: 5
        }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box component={Link} to={`home`} sx={{ mr: 2, display: "flex" }}>
              {/* logo */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Box
                  component="img"
                  sx={{
                    height: 75,
                    width: 75,
                    maxHeight: 40,
                    maxWidth: 40,
                  }}
                  alt="mu space corp logo"
                  src={
                    // theme.palette.mode === "light" ? MuLogoBlack : MuLogoWhite
                    MuLogoWhite
                  }
                />
                <Box
                  component="img"
                  sx={{ height: 35 }}
                  alt="mu space corp name logo"
                  src={
                    // theme.palette.mode === "light"
                    //   ? MuLogoNameBlack
                    //   :MuLogoNameWhite
                    MuLogoNameWhite
                  }
                />
              </Box>
            </Box>
            {/* menu bar (not full screen) */}
            <Box
              sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
              justifyContent="flex-end"
            >
              <MenuDrawer user={user} />
            </Box>
            {/* menu bar (full screen) */}
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {/* menu button */}
              <MenuButton />
              {/* about button */}
              <AboutButton />
            </Box>
            {/* notification */}
            {/* <Box sx={{ display: { xs: "none", md: "flex" } }}> */}
            {/* <Notification /> */}
            {/* </Box> */}
            {/* <Box sx={{ pr: 2, display: { xs: "none", md: "flex" } }}> */}
            {/* <SettingsDrawer /> */}
            {/* </Box> */}
            {/* profile avatar */}
            <ProfilePicture user={user} />
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}

export default Appbar;
