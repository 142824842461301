import React, { useEffect, useState } from "react";
import { Box, LinearProgress } from "@mui/material";
import { auth } from "./services/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import Form from "./pages/FormGenerator/Form";
import MyAccount from "./pages/MyAccount/MyAccount";
import About from "./pages/About/About";
import CheckUser from "./pages/MainLogin/CheckUser";
import ProcurementForm from "../src/pages/FormGenerator/ProcurementForm/Procurement";

function App() {
  const [user, loading] = useAuthState(auth);
  const [token, setToken] = useState();

  useEffect(() => {
    auth.onAuthStateChanged((authToken) => {
      setToken(authToken);
    });
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <LinearProgress
          sx={{
            backgroundColor: "black",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "gray",
            },
          }}
        />
      </Box>
    );
  }

  return (
    <>
      {token ? (
        <Routes>
          <Route path="/" element={<Dashboard user={user} />} />
          <Route path="/form" element={<Form user={user} />} />
          <Route
            path="/form/procurement"
            element={<ProcurementForm user={user} />}
          />
          <Route path="about" element={<About user={user} />} />

          <Route path="account" element={<MyAccount user={user} />} />

          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="login" element={<CheckUser />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      )}
    </>
  );
}

export default App;
