import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import ServiceButton from "../ServiceButton/ServiceButton";
import ServiceButtonSM from "../ServiceButton/ServiceButtonSM";

const GridLayoutButton = ({ service }) => {
  return (
    <Grid item xs={12} sm={6} lg={3}>
      <Box
        sx={{
          display: { xs: "none", sm: "flex" },
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ServiceButton
          label={service.label}
          route={service.route}
          image={service.image}
        ></ServiceButton>
      </Box>
      <Box
        sx={{
          display: { xs: "flex", sm: "none" },
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ServiceButtonSM
          label={service.label}
          route={service.route}
          image={service.image}
        ></ServiceButtonSM>
      </Box>
    </Grid>
  );
};

export default GridLayoutButton;
