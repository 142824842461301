import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";

import InitialItem from "./InitialItem";

function ItemInfoTable(props) {
  const style = {
    color: "black",
    borderColor: "black",
    padding: 0,
    border: 0.5,
  };

  const { itemArray } = props;

  const toCommas = (number) =>
    number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const itemAmount = (qty, price) => {
    let amount = 0;
    amount = parseFloat(qty) * parseFloat(price);

    return toCommas(amount);
  };

  const handleTableRow = () => {
    for (const element of Object.keys(itemArray)) {
      <>
        <TableRow>
          {/* ----- index ----- */}
          <TableCell align="center" sx={{ ...style, width: "50px" }}>
            <Typography sx={{ fontSize: 14 }}></Typography>
          </TableCell>
          {/* ----- category ----- */}
          <TableCell align="center" sx={{ ...style, width: "150px" }}>
            <Typography sx={{ fontSize: 14 }}></Typography>
          </TableCell>
          {/* ----- name ----- */}
          <TableCell align="left" sx={{ ...style, width: "630px" }}>
            <Typography sx={{ fontSize: 14, paddingLeft: 1 }}></Typography>
          </TableCell>
          {/* ----- qty ----- */}
          <TableCell align="center" sx={{ ...style, width: "120px" }}>
            <Typography sx={{ fontSize: 14 }}></Typography>
          </TableCell>
          {/* ----- unit price ----- */}
          <TableCell align="center" sx={{ ...style, width: "130px" }}>
            <Typography sx={{ fontSize: 14 }}></Typography>
          </TableCell>
          {/* ----- amount ----- */}
          <TableCell align="center" sx={{ ...style, width: "120px" }}>
            <Typography sx={{ fontSize: 14 }}></Typography>
          </TableCell>
        </TableRow>
      </>;
    }
  };

  return (
    <Box sx={{ width: "1200px" }}>
      <Grid container spacing={1}>
        <TableContainer sx={{ width: "1200px", padding: 0 }}>
          <Box
            sx={{
              backgroundColor: "#097969",
              color: "white",
              paddingLeft: 1,
              width: "1200px",
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>ITEM INFORMATION</Typography>
          </Box>
          <Table size="small" sx={{ border: 0.5, width: "1200px" }}>
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    ...style,
                    fontWeight: "bold",
                    width: "50px",
                  }}
                >
                  No.
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    ...style,
                    fontWeight: "bold",
                    width: "150px",
                  }}
                >
                  Category
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    ...style,
                    fontWeight: "bold",
                    width: "630px",
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    ...style,
                    fontWeight: "bold",
                    width: "120px",
                  }}
                >
                  QTY
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    ...style,
                    fontWeight: "bold",
                    width: "130px",
                  }}
                >
                  Unit Price
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    ...style,
                    fontWeight: "bold",
                    width: "120px",
                  }}
                >
                  Amount
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {Object.values(itemArray)?.map((itemJson, index) => (
                  <TableRow key={index}>
                    {/* ----- index ----- */}
                    <TableCell align="center" sx={{ ...style, width: "50px" }}>
                      <Typography sx={{ fontSize: 14 }}>{index + 1}</Typography>
                    </TableCell>
                    {/* ----- category ----- */}
                    <TableCell align="center" sx={{ ...style, width: "150px" }}>
                      <Typography sx={{ fontSize: 14 }}>
                        {itemJson.itemCategory}
                      </Typography>
                    </TableCell>
                    {/* ----- name ----- */}
                    <TableCell align="left" sx={{ ...style, width: "630px" }}>
                      <Typography sx={{ fontSize: 14, paddingLeft: 1 }}>
                        {itemJson.itemName + ": " + itemJson.itemDescription  }
                      </Typography>
                    </TableCell>
                    {/* ----- qty ----- */}
                    <TableCell align="center" sx={{ ...style, width: "120px" }}>
                      <Typography sx={{ fontSize: 14 }}>
                        {itemJson.itemQuantity}
                      </Typography>
                    </TableCell>
                    {/* ----- unit price ----- */}
                    <TableCell align="center" sx={{ ...style, width: "130px" }}>
                      <Typography sx={{ fontSize: 14 }}>
                        {toCommas(itemJson.itemUnitPrice)}
                      </Typography>
                    </TableCell>
                    {/* ----- amount ----- */}
                    <TableCell align="center" sx={{ ...style, width: "120px" }}>
                      <Typography sx={{ fontSize: 14 }}>
                        {itemAmount(
                          itemJson.itemQuantity,
                          itemJson.itemUnitPrice
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
                {Object.values(InitialItem)?.map((itemJson, index) =>
                  index > Object.keys(itemArray).length ? (
                    <TableRow key={index}>
                      {/* ----- index ----- */}
                      <TableCell
                        align="center"
                        sx={{ ...style, width: "50px" }}
                      >
                        <Typography sx={{ fontSize: 14 }}>{index}</Typography>
                      </TableCell>
                      {/* ----- category ----- */}
                      <TableCell
                        align="center"
                        sx={{ ...style, width: "150px" }}
                      >
                        <Typography sx={{ fontSize: 14 }}></Typography>
                      </TableCell>
                      {/* ----- name ----- */}
                      <TableCell align="left" sx={{ ...style, width: "630px" }}>
                        <Typography
                          sx={{ fontSize: 14, paddingLeft: 1 }}
                        ></Typography>
                      </TableCell>
                      {/* ----- qty ----- */}
                      <TableCell
                        align="center"
                        sx={{ ...style, width: "120px" }}
                      >
                        <Typography sx={{ fontSize: 14 }}></Typography>
                      </TableCell>
                      {/* ----- unit price ----- */}
                      <TableCell
                        align="center"
                        sx={{ ...style, width: "130px" }}
                      >
                        <Typography sx={{ fontSize: 14 }}></Typography>
                      </TableCell>
                      {/* ----- amount ----- */}
                      <TableCell
                        align="center"
                        sx={{ ...style, width: "120px" }}
                      >
                        <Typography sx={{ fontSize: 14 }}></Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <Fragment key={index}></Fragment>
                  )
                )}
              </>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Box>
  );
}

export default ItemInfoTable;
