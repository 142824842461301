import React from "react";
import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Typography,
  Divider,
  ToggleButtonGroup,
  ToggleButton,
  styled,
} from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
  Menu,
  Info,
  Settings,
  Home,
  Article,
  PrecisionManufacturing,
  AirportShuttleRounded,
  ShoppingCart,
  Payments,
  CreditCard,
  CalendarMonth,
  Inventory,
  Scale,
  Science,
  Delete,
  MenuBook,
  FactCheck,
  Assignment,
  Work,
  FormatListNumbered,
} from "@mui/icons-material";
import { LightMode, DarkMode, ImportantDevices } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { ColorModeContext } from "../../../../context/ThemeContext";

function MenuDrawerList() {
  // menu
  const [menu, setMenu] = React.useState(true);
  const menuClick = () => {
    setMenu(!menu);
  };

  // form
  const [form, setForm] = React.useState(true);
  const formClick = () => {
    setForm(!form);
  };

  // battery lab
  const [lab, setLab] = React.useState(true);
  const labClick = () => {
    setLab(!lab);
  };

  // 3d printing
  const [printing, setPrinting] = React.useState(true);
  const printingClick = () => {
    setPrinting(!printing);
  };

  // van
  const [van, setVan] = React.useState(true);
  const vanClick = () => {
    setVan(!van);
  };

  // attendance
  const [attendance, setAttendance] = React.useState(true);
  const attendanceClick = () => {
    setAttendance(!attendance);
  };

  // settings
  const [settings, setSettings] = React.useState(true);
  const settingsClick = () => {
    setSettings(!settings);
  };

  // theme
  const ctx = React.useContext(ColorModeContext);
  const [theme, setTheme] = React.useState("dark");

  const handleAlignment = (event, newTheme) => {
    if (newTheme !== null) {
      setTheme(newTheme);
    }
  };

  const TextStyle = styled(Typography)({
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 15,
    color: "text.appbar",
  });

  const MenuDrawerListItems = [
    {
      menuId: 0,
      menuTitle: "Form",
      menuIcon: <Article />,
      menuFunction: form,
      menuClick: formClick,
      submenu: [
        {
          id: 0,
          submenuTitle: "Purchase Requisition",
          submenuIcon: <ShoppingCart />,
          submenuPath: `form/procurement`,
        },

      ],
    },

  ];

  return (
    <Box>
      <List
        sx={{ width: "100%", maxWidth: 360 }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <ListItemButton onClick={menuClick}>
          <ListItemIcon>
            <Menu />
          </ListItemIcon>
          <ListItemText>
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontWeight: "bold",
                pt: 0.5,
                pb: 0.5,
              }}
            >
              Menu
            </Typography>
          </ListItemText>
          {menu ? <ExpandMore /> : <ExpandLess />}
        </ListItemButton>
        <Collapse in={!menu} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton component={Link} to={`home`} sx={{ pl: 4 }}>
              <ListItemIcon>
                <Home />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  sx={{ fontFamily: "Montserrat", fontWeight: "bold" }}
                >
                  Home
                </Typography>
              </ListItemText>
            </ListItemButton>
            {/* menu list */}
            {MenuDrawerListItems.map((menuItem) => {
              return (
                <Box key={menuItem.menuId}>
                  <ListItemButton sx={{ pl: 4 }} onClick={menuItem.menuClick}>
                    <ListItemIcon>{menuItem.menuIcon}</ListItemIcon>
                    <ListItemText>
                      <Typography
                        sx={{ fontFamily: "Montserrat", fontWeight: "bold" }}
                      >
                        {menuItem.menuTitle}
                      </Typography>
                    </ListItemText>
                    {menuItem.menuFunction ? <ExpandMore /> : <ExpandLess />}
                  </ListItemButton>
                  <Collapse
                    in={!menuItem.menuFunction}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {menuItem.submenu.map((submenu) => {
                        return (
                          <ListItemButton
                            key={submenu.id}
                            component={Link}
                            to={submenu.submenuPath}
                            sx={{ pl: 7 }}
                          >
                            <ListItemIcon>{submenu.submenuIcon}</ListItemIcon>
                            <ListItemText>
                              <Typography
                                sx={{
                                  fontFamily: "Montserrat",
                                  fontWeight: "bold",
                                }}
                              >
                                {submenu.submenuTitle}
                              </Typography>
                            </ListItemText>
                          </ListItemButton>
                        );
                      })}
                    </List>
                  </Collapse>
                </Box>
              );
            })}
          </List>
        </Collapse>
        {/* --------------------------------- about and settings --------------------------------- */}
        <Divider />
        {/* ---------- about ---------- */}
        <ListItemButton component={Link} to={`/about`}>
          <ListItemIcon>
            <Info />
          </ListItemIcon>
          <Typography
            sx={{ fontFamily: "Montserrat", fontWeight: "bold", pt: 1, pb: 1 }}
          >
            About
          </Typography>
        </ListItemButton>
        <Divider />
        {/* ---------- settings ---------- */}
        {/* <ListItemButton onClick={settingsClick}>
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          <ListItemText>
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontWeight: "bold",
                pt: 1,
                pb: 1,
              }}
            >
              Settings
            </Typography>
          </ListItemText>
          {settings ? <ExpandMore /> : <ExpandLess />}
        </ListItemButton> */}
        <Collapse in={!settings} timeout="auto" unmountOnExit>
          <List component="div" sx={{ pl: 2, pr: 2 }}>
            <Typography
              sx={{ fontSize: 16, fontWeight: "bold", paddingBottom: 1 }}
            >
              Theme
            </Typography>
            <Box>
              <ToggleButtonGroup
                value={theme}
                orientation="vertical"
                exclusive
                onChange={handleAlignment}
                aria-label="text alignment"
                sx={{ height: 50, width: 280 }}
              >
                <ToggleButton
                  value="light"
                  onClick={ctx.setLightMode}
                  sx={{ p: 1.5 }}
                >
                  <LightMode sx={{ pr: 2, fontSize: 40 }} />
                  <TextStyle>Light</TextStyle>
                </ToggleButton>
                <ToggleButton
                  value="system"
                  onClick={ctx.toggleColorMode}
                  sx={{ p: 1.5 }}
                >
                  <ImportantDevices sx={{ pr: 2, fontSize: 40 }} />
                  <TextStyle>System</TextStyle>
                </ToggleButton>
                <ToggleButton
                  value="dark"
                  onClick={ctx.setDarkMode}
                  sx={{ p: 1.5 }}
                >
                  <DarkMode sx={{ pr: 2, fontSize: 40 }} />
                  <TextStyle>Dark</TextStyle>
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </List>
        </Collapse>
      </List>
    </Box>
  );
}

export default MenuDrawerList;
