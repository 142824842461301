import documentIcon from "../../../assets/icons/documentIcon.png";
import batteryIcon from "../../../assets/icons/batteryIcon.png";
import robotarmIcon from "../../../assets/icons/robotarmIcon.png";
import vanIcon from "../../../assets/icons/vanIcon.png";
import calendarIcon from "../../../assets/icons/calendarIcon.png";

const DashboardItems = [
  {
    id: 0,
    image: documentIcon,
    label: "Form",
    route: "form",
  },

];

export default DashboardItems;
