import "./Stepper.css";

import * as React from "react";

import { ArrowBackOutlined, ArrowForwardOutlined } from "@mui/icons-material";
import {
    Box,
    Button,
    MobileStepper,
    Paper,
    Step,
    StepLabel,
    Stepper,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';

export default function Steppers(props) {
    const {
        steps,
        activeStep,
        handleBack,
        handleNext,
        vendorObject,
        shippingObject,
        optionalInfo,
        itemArray,
        sendLoading,
    } = props;

    const mediaQuery = useMediaQuery("(min-width: 900px)");
    const theme = useTheme();

    const toggleEnable = () => {
        // Need to check if every required value is filled to enable the "Next" button
        // This serves as the validation check on the frontend
        if (activeStep === 1) {
            return !(vendorObject["vendorCode"] && 
                     vendorObject["vendorName"] && 
                     vendorObject["vendorEmail"] && 
                     vendorObject["vendorAddress"] && 
                     vendorObject["requestorLocation"] && 
                     vendorObject["requestorName"] && 
                     vendorObject["requestorEmail"]) 

        } else if (activeStep === 2) {
           return !(shippingObject["shippingVia"] && 
                    shippingObject["shippingMethod"] && 
                    shippingObject["deliveryTerms"] && 
                    shippingObject["deliveryDate"] && 
                    shippingObject["projectName"] && 
                    shippingObject["projectPurpose"] && 
                    shippingObject["projectPurpose"].length > 40 &&
                    shippingObject["termOfPayment"] && 
                    shippingObject["paymentMethod"] && 
                    shippingObject["currency"] &&
                    shippingObject["paymentDue"])

                    // Optionalinfo adjustments is explicit in definition because truthy interprets 0 as false
        } else if (activeStep === 3) {
            return !(
                optionalInfo["adjustments"] !== undefined && 
                optionalInfo["location"] && 
                optionalInfo["vat"] !== undefined
            ) 
        }
    };

    return mediaQuery ? (
        <Paper elevation={3} id="stepper-container">
            <Button
                color="inherit"
                variant="outlined"
                startIcon={<ArrowBackOutlined />}
                sx={{ mr: 1 }}
                disabled={activeStep === 1}
                style={{ minWidth: "95px" }}
                onClick={handleBack}
            >
                Back
            </Button>

            <Box sx={{ width: "100%" }}>
                <Stepper alternativeLabel activeStep={activeStep - 1}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
            <LoadingButton
                disabled={toggleEnable()}
                variant="contained"
                endIcon={<ArrowForwardOutlined />}
                style={{ minWidth: "95px" }}
                onClick={handleNext}
                loading={sendLoading}
                loadingPosition="end"
            >
                {activeStep === steps.length ? "Finish" : "Next"}
            </LoadingButton>
        </Paper>
    ) : (
        <MobileStepper
            variant="dots"
            steps={4}
            position="static"
            activeStep={activeStep - 1}
            sx={{ flexGrow: 1 }}
            style={{ backgroundColor: "#eeeeee", borderRadius: "10px" }}
            nextButton={
                <Button
                    variant="contained"
                    size="small"
                    onClick={handleNext}
                disabled={toggleEnable()}
                >
                    {theme.direction === "rtl" ? (
                        <ArrowBackOutlined />
                    ) : (
                        <ArrowForwardOutlined />
                    )}
                </Button>
            }
            backButton={
                <Button
                    color="inherit"
                    variant="outlined"
                    size="small"
                    onClick={handleBack}
                    disabled={activeStep === 1}
                >
                    {theme.direction === "rtl" ? (
                        <ArrowForwardOutlined />
                    ) : (
                        <ArrowBackOutlined />
                    )}
                </Button>
            }
        />
    );
}
