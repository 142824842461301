import App from "./App";
import AppThemeProvider from "./services/AppThemeProvider";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom/client";
import './index.css'

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <AppThemeProvider>
    <CssBaseline />
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </AppThemeProvider>
);
