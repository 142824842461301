import googleLogo from "../../assets/logo/google_logo.png";
import { React } from "react";
import { auth, provider } from "../../services/firebase.js";
import { signInWithPopup } from "firebase/auth";
import { Box, Button, Typography } from "@mui/material";

function LoginButton() {
  const signInWithGoogle = () =>
    signInWithPopup(auth, provider).then((userCre) => {
      if (!userCre["user"]["email"].endsWith("@muspacecorp.com")) {
        alert("Please use a muspacecorp domain to login");
        auth.signOut();
      }
    });
  return (
    <Box sx={{ bgcolor: "#525252", borderRadius: "10px" }}>
      <Button sx={{ p: 1.5, color: "white" }} onClick={signInWithGoogle}>
        <Box
          component="img"
          sx={{ height: 30, pr: 2 }}
          alt="google logo in login button"
          src={googleLogo}
        />
        <Typography
          sx={{ fontSize: 18, fontWeight: "bold", fontFamily: "Montserrat" }}
        >
          Login with Google
        </Typography>
      </Button>
    </Box>
  );
}

export default LoginButton;
