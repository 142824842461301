import * as React from "react";
import Appbar from "../../components/Appbar/Appbar.js";
import DashboardContent from "./DashboardContent/DashboardContent.js";
import { Box } from "@mui/material";
import { useTheme } from "@emotion/react";

function Dashboard({ user }) {
  const theme = useTheme();

  return (
    <Box sx={{
      minHeight: '100vh',
      background: 'black',
      // background: theme.palette.background.dashboardGradient,
    }}>
      <Appbar user={user} />
      <DashboardContent />
    </Box >
  );
}

export default Dashboard;
