import * as React from "react";
import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

function AboutButton() {
  return (
    <>
      <Button
        sx={{ mx: 2, color: "white" }}
        component={Link}
        to={`/about`}
      >
        <Typography sx={{ fontFamily: "Montserrat", fontWeight: "bold" }}>
          About
        </Typography>
      </Button>
    </>
  );
}

export default AboutButton;
