import office from "../../assets/etc/office.jpg";
import fac0 from "../../assets/etc/fac0.jpg";
import fac1 from "../../assets/etc/fac1.jpg";
import fac2 from "../../assets/etc/fac2.jpg";

const LocationCardItems = [
  {
    id: 0,
    image: office,
    title: "Head Office",
    address:
      "12A Floor, Pearl Bangkok Building, Room 12A04-05, 1177 Phahonyothin Rd, Khwaeng Phaya Thai, Phaya Thai, Bangkok 10400, Thailand",
    route:
      "https://www.google.com/maps/place/mu+Space+Corp/@13.778085,100.543459,15z/data=!4m5!3m4!1s0x0:0x1b69df5b4d63a506!8m2!3d13.7780317!4d100.5435473?hl=en-GB",
  },
  {
    id: 1,
    image: fac0,
    title: "Factory 00",
    address:
      "71, 31 Vibhavadi Rangsit 64 Alley, Talat Bang Khen, Lak Si, Bangkok 10210",
    route:
      "https://www.google.com/maps/place/mu+Space+Factory+0/@13.8615035,100.599025,13z/data=!4m5!3m4!1s0x30e283001fac443b:0x1d11b6c81600e422!8m2!3d13.8746251!4d100.5794705?hl=en-GB&shorturl=1",
  },
  {
    id: 2,
    image: fac1,
    title: "Factory 01",
    address:
      "160, 2 Vibhavadi Rangsit Rd, Talat Bang Khen, Lak Si, Bangkok 10210",
    route:
      "https://www.google.com/maps/place/mu+Space+Factory+1/@13.870914,100.574764,15z/data=!4m5!3m4!1s0x0:0x6d292356de4a7753!8m2!3d13.8709139!4d100.5747643?hl=en-GB&shorturl=1",
  },
  {
    id: 3,
    image: fac2,
    title: "Factory 02",
    address:
      "158 Soi Vibhavadi Rangsit 62 Yaek 1, Talat Bang Khen, Lak Si, Bangkok 10210",
    route:
      "https://www.google.co.th/maps/place/mu+Space+Factory+2/@13.8721387,100.5785541,17z/data=!3m1!4b1!4m5!3m4!1s0x30e2839a78b5660f:0x8e338858e9e39ef3!8m2!3d13.8721411!4d100.5785778?hl=en",
  },
];

export default LocationCardItems;
