import React from "react";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Avatar,
  Tooltip,
  Typography,
  ListItemIcon,
  Paper,
  Divider,
  Stack,
  MenuList,
} from "@mui/material";
import { auth } from "../../../services/firebase.js";
import { Logout, AccountCircle } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { borderColor } from "@mui/system";

function ProfilePicture({ user }) {
  const [profile, setProfile] = React.useState(null);
  const open = Boolean(profile);
  const profileClick = (event) => {
    setProfile(event.currentTarget);
  };
  const profileClosed = () => {
    setProfile(null);
  };

  return (
    <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
      <IconButton
        sx={{ p: 0 }}
        onClick={profileClick}
        size="small"
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <Tooltip title={user.displayName}>
          <Avatar alt="profile-pic" src={user.photoURL} />
        </Tooltip>
      </IconButton>
      <Menu
        id="account-menu"
        anchorEl={profile}
        open={open}
        onClose={profileClosed}
        onClick={profileClosed}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          // spacing={0.5}
          width="25vw"
          maxWidth={'400px'}
          my={1}
        >
          <Avatar
            alt="profile-pic"
            src={user.photoURL}
            sx={{
              minWidth: '72px', minHeight: '72px', marginY: 2
            }}
          />
          <Typography fontWeight={'bold'}  >{user.displayName}</Typography>
          <Typography sx={{ marginBottom: 1 }}>{user.email}</Typography>
          <Divider
            variant="fullWidth"
            orientation="horizontal"
            sx={{ bgcolor: "text.primary", borderBottomWidth: 2, width: '100%', my: 2 }} />
          <MenuItem sx={{ border: 1, borderRadius: 2 }} onClick={() => auth.signOut()} >
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            <Typography sx={{ fontFamily: "Montserrat", fontWeight: "bold" }}>
              Sign out
            </Typography>
          </MenuItem>
        </Stack>
      </Menu>
    </Box>
  );
}

export default ProfilePicture;
