import "./index.css";

import {
  Autocomplete,
  MenuItem,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
  deliveryTerm,
  itemCurrency,
  paymentMethod,
  projectName,
  shippingMethod,
  termsOfPayment,
} from "./MenuConstants";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React from "react";
export default function ShippingCondition(props) {
  const {
    values,
    handleAutoComplete,
    handleChange,
    handleDeliveryDate,
    handlePaymentDueDate,
    handleTermofPaymentAutoComplete,
    handleCurrencyAutoComplete,
    shippingVia,
  } = props;
  const mediaQuery = useMediaQuery("(min-width: 900px)");
  return (
    <div className="flexbox-wrapper long-element">
      <Paper
        elevation={3}
        className="flexbox-wrapper container narrow-element flex-auto"
      >
        <Typography variant={mediaQuery ? "h5" : "h6"}>
          Shipping{" "}
          <Typography variant="caption">(Fill all the blank)</Typography>
        </Typography>

        <section className="flexbox-wrapper wide-element">
          <Autocomplete
            freeSolo
            disableClearable
            className="flex-px"
            options={shippingVia.map((item) => item.brand)}
            value={values.shippingVia || ""}
            onChange={(e, value) => handleAutoComplete(e, value)}
            onInputChange={(e, value) =>
              handleAutoComplete("shippingVia", value)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                required
                size="small"
                variant="outlined"
                label="Ship Via"
                name="shippingVia"
              />
            )}
          />

          <TextField
            required
            select
            size="small"
            variant="outlined"
            label="Shipping Method"
            name="shippingMethod"
            className="flex-px"
            value={values.shippingMethod || ""}
            onChange={handleChange}
          >
            {shippingMethod.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </TextField>
        </section>

        <section className="flexbox-wrapper wide-element">
          <TextField
            required
            select
            size="small"
            variant="outlined"
            label="Delivery Terms"
            name="deliveryTerms"
            helperText={
              values.deliveryTerms === "DDP" && (
                <Typography variant="caption">
                  <strong>DDP</strong> has an effect on Adjustments
                </Typography>
              )
            }
            style={{ flex: "1 1 200px" }}
            value={values.deliveryTerms || ""}
            onChange={handleChange}
          >
            {deliveryTerm.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </TextField>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              // disablePast
              // Expected Delivery is the same as delivery date
              label="Expected Delivery"
              inputFormat="MM/dd/yyyy"
              name="deliveryDate"
              type="date"
              value={new Date(values.deliveryDate) || null}
              onChange={(newValue) => handleDeliveryDate(newValue)}
              componentsProps={{
                textField: {
                  variant: "outlined",
                  style: { flex: "1 1 200px" },
                },
              }}
            />
          </LocalizationProvider>
        </section>

        <section className="flexbox-wrapper wide-element">
          <TextField
            required
            select
            size="small"
            variant="outlined"
            label="Project Name"
            name="projectName"
            style={{ flex: "1 1 200px" }}
            value={values.projectName || ""}
            onChange={handleChange}
          >
            {projectName.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            required
            size="small"
            variant="outlined"
            label="Purpose"
            helperText={
              values.projectPurpose.length < 41 &&
              values.projectPurpose.length >= 1 &&
              `Please input ${
                41 - values.projectPurpose.length
              } more characters`
            }
            error={
              values.projectPurpose.length < 41 &&
              values.projectPurpose.length >= 1
            }
            name="projectPurpose"
            className="flex-px"
            defaultValue={values.projectPurpose || ""}
            onChange={handleChange}
          />
        </section>
      </Paper>

      <Paper
        elevation={3}
        className="flexbox-wrapper container narrow-element flex-auto"
      >
        <Typography variant={mediaQuery ? "h5" : "h6"}>
          Condition{" "}
          <Typography variant="caption">(Fill all the blank)</Typography>
        </Typography>

        <section className="flexbox-wrapper wide-element">
          <Autocomplete
            freeSolo
            className="flex-px"
            options={termsOfPayment.map((item, index) => (index, item))}
            variant="outlined"
            style={{ flex: "1 1 100px" }}
            value={values.termOfPayment || ""}
            onChange={(e, value) => handleTermofPaymentAutoComplete(e, value)}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                size="small"
                label="Term of payment"
                name="termOfPayment"
              />
            )}
          />
          <TextField
            disabled={
              values.termOfPayment === undefined ||
              !(values.termOfPayment === "Other")
            }
            size="small"
            label="Custom term of Payment"
            name="customTermOfPayment"
            helperText={
              <Typography variant="caption">
                Only available under "other"
              </Typography>
            }
            style={{ flex: "1 1 100px" }}
            value={values.customTermOfPayment || ""}
            onChange={handleChange}
          />
          <TextField
            select
            required
            size="small"
            variant="outlined"
            label="Payment Method"
            name="paymentMethod"
            helperText={
              <Typography variant="caption">
                Corporate card: online stores, less than 40K Baht
              </Typography>
            }
            style={{ flex: "1 1 100px" }}
            value={values.paymentMethod || ""}
            onChange={handleChange}
          >
            {paymentMethod.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </TextField>
        </section>

        <section className="flexbox-wrapper wide-element">
          <TextField
            size="small"
            variant="outlined"
            label="Delay Penalty"
            name="delayPenalty"
            className="flex-px"
            defaultValue={values.delayPenalty}
            onChange={handleChange}
          />
          <TextField
            size="small"
            variant="outlined"
            label="Defect and Warranty"
            name="defectWarning"
            className="flex-px"
            defaultValue={values.defectWarning}
            onChange={handleChange}
          />
        </section>

        <section className="flexbox-wrapper wide-element">
          <TextField
            required
            select
            size="small"
            variant="outlined"
            label="Currency"
            name="currency"
            style={{ flex: "1 1 50px" }}
            value={values.currency || ""}
            onChange={(e, currency) => handleCurrencyAutoComplete(e, currency)}
          >
            {itemCurrency.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            size="small"
            type="number"
            variant="outlined"
            label="Exchange Rate"
            name="exchangeRate"
            helperText={
              <Typography variant="caption">
                Don't input anything for auto-pull
              </Typography>
            }
            className="flex-px"
            defaultValue={values.exchangeRate}
            onChange={handleChange}
          />
        </section>

        <section className="flexbox-wrapper wide-element">
          <TextField
            size="small"
            variant="outlined"
            label="Contract No."
            name="contractNo"
            className="flex-px"
            defaultValue={values.contractNo}
            onChange={handleChange}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Payment Due"
              inputFormat="MM/dd/yyyy"
              name="paymentDue"
              value={new Date(values.paymentDue) || null}
              onChange={(newValue) => handlePaymentDueDate(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  type="date"
                  size="small"
                  variant="outlined"
                  style={{ flex: "1 1 200px" }}
                />
              )}
            />
          </LocalizationProvider>
        </section>
      </Paper>
    </div>
  );
}
