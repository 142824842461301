import "./index.css";

import {
  Autocomplete,
  MenuItem,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { requestorTeam, requestorLocation } from "./MenuConstants";
import React from "react";
export default function VendorRequestor(props) {
  const { values, handleVendorAutoComplete, handleRequestorAutoComplete, handleChange, vendorList, userList } =
    props;
  const mediaQuery = useMediaQuery("(min-width: 900px)");
  return (
    <div className="flexbox-wrapper long-element">
      <Paper
        elevation={3}
        className="flexbox-wrapper container narrow-element flex-auto"
      >
        <Typography variant={mediaQuery ? "h5" : "h6"}>
          Vendor <Typography variant="caption">(Fill all the blank)</Typography>
        </Typography>

        <section className="flexbox-wrapper wide-element">
          <Autocomplete
            freeSolo
            disableClearable
            id="vendorCode"
            className="flex-auto"
            options={vendorList.map((item) => `${item.code} - ${item.name}`)}
            onChange={(e, value) => handleVendorAutoComplete(e, value)}
            onInputChange={(e, value) => handleVendorAutoComplete(e, value)}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                size="small"
                variant="outlined"
                label="Code & Name Autocomplete"
              />
            )}
          />
        </section>

        <section className="flexbox-wrapper wide-element">
          <TextField
            required
            size="small"
            disabled={values.Autocomplete !== ""}
            variant="outlined"
            label="Code"
            name="vendorCode"
            style={{ flex: "1 1 50px" }}
            value={values.vendorCode || ""}
            onChange={handleChange}
          />
          <TextField
            required
            size="small"
            disabled={values.Autocomplete !== ""}
            variant="outlined"
            label="Name"
            name="vendorName"
            className="flex-px"
            value={values.vendorName || ""}
            onChange={handleChange}
          />
        </section>

        <section className="flexbox-wrapper wide-element">
          <TextField
            size="small"
            variant="outlined"
            label="Contact Name"
            name="vendorContact"
            className="flex-px"
            value={values.vendorContact || ""}
            onChange={handleChange}
          />
        </section>

        <section className="flexbox-wrapper wide-element">
          <TextField
            required
            size="small"
            variant="outlined"
            type="email"
            label="E-mail"
            name="vendorEmail"
            className="flex-px"
            value={values.vendorEmail || ""}
            onChange={handleChange}
          />
          <TextField
            size="small"
            variant="outlined"
            label="Phone"
            name="vendorPhone"
            className="flex-px"
            value={values.vendorPhone || ""}
            onChange={handleChange}
          />
        </section>

        <section className="flexbox-wrapper wide-element">
          <TextField
            required
            size="small"
            variant="outlined"
            disabled={values.Autocomplete !== ""}
            label="Address"
            name="vendorAddress"
            className="flex-px"
            value={values.vendorAddress || ""}
            onChange={handleChange}
          />
        </section>
      </Paper>

      <Paper
        elevation={3}
        className="flexbox-wrapper container narrow-element flex-auto"
      >
        <Typography variant={mediaQuery ? "h5" : "h6"}>
          Requestor{" "}
          <Typography variant="caption">(Fill all the blank)</Typography>
        </Typography>

        <section className="flexbox-wrapper wide-element">
          <TextField
            required
            select
            size="small"
            variant="outlined"
            label="Location"
            name="requestorLocation"
            className="flex-px"
            value={values.requestorLocation || ""}
            onChange={handleChange}
          >
            {requestorLocation.map((item, index) => (
              <MenuItem key={index} value={item.fullLocation}>
                {item.shortLocation}
              </MenuItem>
            ))}
          </TextField>
        </section>

        <section className="flexbox-wrapper wide-element">
          <Autocomplete
            freeSolo
            disableClearable
            className="flex-px"
            options={userList.map(
              (item) => `${item.firstname} ${item.lastname}`
            )}
            value={values.requestorName || ""}
            onChange={(e, value) => handleRequestorAutoComplete(e, value)}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                size="small"
                variant="outlined"
                label="Requested by"
                name="requestorName"
              />
            )}
          />
          <TextField
            required
            size="small"
            variant="outlined"
            label="E-mail"
            name="requestorEmail"
            className="flex-px"
            value={values.requestorEmail || ""}
            onChange={handleChange}
          />
        </section>

        <section className="flexbox-wrapper wide-element">
          <TextField
            size="small"
            variant="outlined"
            label="Phone"
            name="requestorPhone"
            className="flex-px"
            value={values.requestorPhone || ""}
            onChange={handleChange}
          />
          <TextField
            select
            size="small"
            variant="outlined"
            label="Team"
            name="requestorTeam"
            className="flex-px"
            value={values.requestorTeam || ""}
            onChange={handleChange}
          >
            {requestorTeam.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </TextField>
        </section>
      </Paper>
    </div>
  );
}
